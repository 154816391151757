import { useEffect, useState } from "react";

// Views
import DataTable from "views/Tables/DataTable";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Dialog } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// ProductsList page components
import CustomIdCell from "./IdCell";

// Utils
import { parseDate } from "utils/parseDate";
import { formatNumberToMoney } from "utils/formatNumberToMoney";
import { getDateByMonth } from "utils/getDateByMonth";
import { getNumControl } from "utils/getNumControl";
import { parseShortDate } from "utils/parseShortDate";

// Custom Components
import SellsDropdown from "./SellsDropdown";
import SelectedItemsMenu from "./SelectedItemsMenu";
import DialogEmail from "layouts/ventas/components/DialogEmail";
import Step1 from "../../components/StepContent/Step1";
import Step2 from "../../components/StepContent/Step2";
import Step3 from "../../components/StepContent/Step3";

// Queries
import { INVALIDATE_GENERAL_DOCUMENT } from "apollo/mutations/dtes/invalidateGeneralDocument";
import { GET_COMPANIES_BY_USER } from "apollo/queries/companies/getCompaniesByUser";
import { SEND_DTE_BY_EMAIL } from "apollo/mutations/dtes/sendDteByEmail";
import { INVALIDATE_DTE_DOCUMENT } from "apollo/mutations/dtes/invalidateDTEDocument";

// Graphql
import { OperationVariables, LazyQueryExecFunction, useMutation, useLazyQuery } from "@apollo/client";

// Data
import { sellsDataTableData, sellsDataTableDataMobile, sellsDataTableDataMobileMd, sellsDataTableDataMobileSm } from "../data/dataTableData";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Constants
import { PAGINATION } from "constants/pagination";

// Context
import { useSellsConfigContext } from "context/SellsConfigContext";
import { useCompanyContext } from "context/CompanyContext";

// Libs
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// Types
import { SerializationKeys } from "types/apollo";
import { InvalidTypes, RazonDeInvalidacion } from "types/InvalidDtesType";
import { FormikValues } from "layouts/ventas/verDtesRechazados/components/DtesRechazadosTable";
import { GeneralDocument } from "types/documents";
import { Environment } from "types/environment";
import { IvaDocumentTypes } from "types/iva-document";

export interface DTE_INFO {
  idDte?: number;
  nroDTE: number;
  generationCode: any;
  clientName: {
    label: string;
    id: number;
  };
  date: string;
  ivaDocumentName: string;
  amount: number;
  idEmployeeCompany: number;
}

export interface RequestorInfo {
  name: string;
  docNumber: string;
  docType: string;
  idMainEntiry: number;
}

interface Props {
  data: GeneralDocument[];
  getData: LazyQueryExecFunction<any, OperationVariables>;
  refetch?: any;
}

const SellsTable = ({ data = [], getData }: Props) => {
  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  // States
  const [selectedItems, setSelectedItems] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [dteInformation, setDteInformation] = useState<DTE_INFO>();
  const [requestorInfo, setRequestorInfo] = useState<RequestorInfo>();
  const [open, setOpen] = useState(false);
  const [idDteEmail, setIdDteEmail] = useState<number>();

  //Context
  const { company } = useCompanyContext();
  const { sellsConfig } = useSellsConfigContext();

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  //Graphql
  const [sendDteByEmail, { loading: loadingEmail }] = useMutation(SEND_DTE_BY_EMAIL, { context });
  const [invalidateGeneralDocument, dataInvalidateGeneralDocument] = useMutation(INVALIDATE_GENERAL_DOCUMENT, { context });
  const [invalidateDTEDocument, dataInvalidateDTEDocument] = useMutation(INVALIDATE_DTE_DOCUMENT, { context });
  const [getCompanies, result] = useLazyQuery(GET_COMPANIES_BY_USER, { context });

  const navigate = useNavigate();

  const validationSchema = Yup.object({
    razonDeInvalidacion: Yup.string().required("La razon de por que invalidas el DTE es requerida").nullable(),
    description: Yup.string().required("La descripcion es requerida").min(5, "La descripcion debe tener al menos 5 caracteres"),
    nroDTE: Yup.string().oneOf([String(dteInformation?.nroDTE), null], "El numero de DTE debe coincidir"),
    client: Yup.object({
      id: Yup.number(),
      label: Yup.string(),
    }).nullable(),
    nroDTEReemplazo: Yup.object({
      generationCodeDte: Yup.string(),
      label: Yup.string(),
    })
      .required("El Dte de reemplazo es requerido")
      .nullable(),
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      nroDTE: "",
      razonDeInvalidacion: "",
      client: {
        label: "",
        id: 0,
      },
      nroDTEReemplazo: {
        label: "",
        generationCodeDte: "",
      },
      description: "",
      emails: [],
      email: "",
    },
    onSubmit: async (values) => {
      if (dteInformation.ivaDocumentName === IvaDocumentTypes.EXTENDED_FEX) {
        invalidateDTEDocument({
          variables: {
            input: {
              codigoGeneracionAInvalidar: dteInformation.generationCode,
              codigoGeneracionAReemplazar: values.razonDeInvalidacion === "ERROR EN LA INFORMACION DEL DTE" || values.razonDeInvalidacion === "OTRO" ? values.nroDTEReemplazo?.generationCodeDte : null,
              motivoInvalidacion: values.description,
              tipoInvalidacion: getInvalidationType(values.razonDeInvalidacion),
            },
          },
        })
          .then(({ data }) => {
            if (data.invalidateDTEDocument?.isSuccess) {
              toast.success("DTE invalidado correctamente");
              setOpenDelete(false);
              setSelectedItems([]);
              setDteInformation(null);
              formik.resetForm({
                values: {
                  nroDTE: "",
                  razonDeInvalidacion: "",
                  client: {
                    label: "",
                    id: 0,
                  },
                  nroDTEReemplazo: {
                    label: "",
                    generationCodeDte: "",
                  },
                  description: "",
                  emails: [],
                  email: "",
                },
              });
              setActiveStep(0);
              getData({
                variables: {
                  filters: {
                    idCostCenter: sellsConfig.costCenter.id,
                    idSalePoint: sellsConfig.selectedSalePoint.id,
                    startDateSale: sellsConfig.emisionStartDate || getDateByMonth(),
                    pagination: 1000000,
                  },
                },
                notifyOnNetworkStatusChange: true,
              });
            } else {
              toast.error(`Hubo un error al invalidar el DTE ${data.invalidateDTEDocument.message.detail}`);
              setOpenDelete(false);
              setSelectedItems([]);
              setDteInformation(null);
              formik.resetForm({
                values: {
                  nroDTE: "",
                  razonDeInvalidacion: "",
                  client: {
                    label: "",
                    id: 0,
                  },
                  nroDTEReemplazo: {
                    label: "",
                    generationCodeDte: "",
                  },
                  description: "",
                  emails: [],
                  email: "",
                },
              });
              setActiveStep(0);
            }
          })
          .catch((e) => {
            toast.error(`Hubo un error al invalidar el DTE ${e}`);
            setOpenDelete(false);
            setSelectedItems([]);
            setDteInformation(null);
            formik.resetForm();
            setActiveStep(0);
          });
      } else {
        invalidateGeneralDocument({
          variables: {
            input: {
              idGeneralDocument: dteInformation.idDte,
              idEmployeeCompany: dteInformation.idEmployeeCompany,
              data: {
                invalidationType: getInvalidationType(values.razonDeInvalidacion),
                invalidationMotive: values.description,
                dteGenerationCodeReplace: values.razonDeInvalidacion === "ERROR EN LA INFORMACION DEL DTE" || values.razonDeInvalidacion === "OTRO" ? values.nroDTEReemplazo?.generationCodeDte : null,
                requestorName: requestorInfo?.name,
                requestorDocNumber: requestorInfo?.docNumber,
                requestorDocType: requestorInfo?.docType,
              },
            },
          },
        })
          .then((data) => {
            if (data.data.invalidateGeneralDocument.isSuccess) {
              toast.success("DTE invalidado correctamente");
              setOpenDelete(false);
              setSelectedItems([]);
              setDteInformation(null);
              formik.resetForm({
                values: {
                  nroDTE: "",
                  razonDeInvalidacion: "",
                  client: {
                    label: "",
                    id: 0,
                  },
                  nroDTEReemplazo: {
                    label: "",
                    generationCodeDte: "",
                  },
                  description: "",
                  emails: [],
                  email: "",
                },
              });
              setActiveStep(0);
              getData({
                variables: {
                  filters: {
                    idCostCenter: sellsConfig.costCenter.id,
                    idSalePoint: sellsConfig.selectedSalePoint.id,
                    startDateSale: sellsConfig.emisionStartDate || getDateByMonth(),
                    pagination: 1000000,
                  },
                },
                notifyOnNetworkStatusChange: true,
              });
            } else {
              toast.error(`Hubo un error al invalidar el DTE ${data.data.invalidateGeneralDocument.message.detail}`);
              setOpenDelete(false);
              setSelectedItems([]);
              setDteInformation(null);
              formik.resetForm({
                values: {
                  nroDTE: "",
                  razonDeInvalidacion: "",
                  client: {
                    label: "",
                    id: 0,
                  },
                  nroDTEReemplazo: {
                    label: "",
                    generationCodeDte: "",
                  },
                  description: "",
                  emails: [],
                  email: "",
                },
              });
              setActiveStep(0);
            }
          })
          .catch((e) => {
            toast.error(`Hubo un error al invalidar el DTE ${e}`);
            setOpenDelete(false);
            setSelectedItems([]);
            setDteInformation(null);
            formik.resetForm();
            setActiveStep(0);
          });
      }
    },
    validationSchema,
  });

  // Functions
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete: any = async () => {
    await formik.resetForm();
    setActiveStep(0);
    setDteInformation(null);
    setOpenDelete(false);
  };
  const stepContent = (value: number) => {
    switch (value) {
      case 0:
        return <Step1 handleCloseDelete={handleCloseDelete} setActiveStep={setActiveStep} />;
      case 1:
        return <Step2 handleCloseDelete={handleCloseDelete} setActiveStep={setActiveStep} formik={formik} dteInformation={dteInformation} />;
      case 2:
        return <Step3 formik={formik} handleCloseDelete={handleCloseDelete} dteInformation={dteInformation} dataInvalidateGeneralDocument={dataInvalidateGeneralDocument} dataInvalidateDTEDocument={dataInvalidateDTEDocument} />;
    }
  };
  function getInvalidationType(value: string) {
    switch (value) {
      case RazonDeInvalidacion.ERROR_EN_LA_INFORMACION_DEL_DTE:
        return InvalidTypes.INFORMACION_INCORRECTA;
      case RazonDeInvalidacion.YA_NO_SE_VA_A_LLEVAR_A_CABO_LA_OPERACION:
        return InvalidTypes.RESCINDIR_OPERACION;
      case RazonDeInvalidacion.OTRO:
        return InvalidTypes.OTRO;
      default:
        return value;
    }
  }
  function handleClickOpenEmail(selectedItem: any) {
    formik.setFieldValue("email", selectedItem?.clientCompany?.emailNotifications);
    setIdDteEmail(selectedItem.id);
    setOpen(true);
  }
  function handleCloseEmail() {
    setOpen(false);
    formik.setFieldValue("email", "");
    formik.setFieldTouched("email", false);
    formik.setFieldValue("emails", "");
    formik.setFieldTouched("emails", false);
    setIdDteEmail(null);
  }
  const sendEmail = () => {
    sendDteByEmail({ variables: { data: { idSale: idDteEmail, emails: formik.values.emails } } })
      .then(({ data }) => {
        if (data.sendDte.isSuccess) {
          toast.success("Email enviado correctamente");
          handleCloseEmail();
        }
        !data.sendDte.isSuccess && toast.error("Ocurrió un error al enviar el DTE, contacte al administrador");
      })
      .catch((err) => {
        err && toast.error("Ocurrió un error al enviar el DTE, contacte al administrador");
      });
  };
  const getEnvironmentRow = (value: string) => {
    switch (value) {
      case Environment.PRODUCTION:
        return Environment.EmisionesReales;
      case Environment.TESTING_MH:
        return Environment.PruebasMH;
      case Environment.INTERNAL:
        return Environment.PruebasInternas;
      default:
        return value;
    }
  };

  const rows: any = rowsData?.map((item: any) => {
    if (item) {
      return {
        select: item.id && <CustomIdCell checked={selectedItems.includes(item) ? true : false} idDoc={item.id} selectedItem={item} setChecked={setSelectedItems} />,
        num_control: item?.dteControlNumber && item?.ivaDocument && item?.ivaDocument?.name_document && `#${getNumControl(item?.dteControlNumber)} - ${item?.ivaDocument?.name_document}`,
        date: item?.createdAt && parseDate(item.createdAt),
        state: item?.status && item.status,
        client: item?.client ?? "Cliente anonimo",
        environment: item?.environment && getEnvironmentRow(item?.environment),
        amount: item?.totalToPay && formatNumberToMoney(item?.totalToPay),
        actions: item.id && <SellsDropdown handleClickOpenEmail={handleClickOpenEmail} id={item.id} selectedItem={item} status={item?.status} setDteInformation={setDteInformation} handleOpenDelete={handleOpenDelete} />,
      };
    }
  });

  const rowsMobile: any = rowsData?.map((item) => {
    if (item) {
      return {
        select: item.id && <CustomIdCell checked={selectedItems.includes(item) ? true : false} idDoc={item.id} selectedItem={item} setChecked={setSelectedItems} />,
        num_control: item?.dteControlNumber && item?.ivaDocument && item?.ivaDocument?.name_document && `#${getNumControl(item?.dteControlNumber)} - ${item?.ivaDocument?.name_document}`,
        date: item?.createdAt && parseShortDate(item.createdAt),
        state: item?.status && item.status,
        client: item?.client ?? "Cliente anonimo",
        amount: item?.totalToPay && formatNumberToMoney(item?.totalToPay),
        actions: item.id && <SellsDropdown handleClickOpenEmail={handleClickOpenEmail} id={item.id} selectedItem={item} status={item?.status} setDteInformation={setDteInformation} handleOpenDelete={handleOpenDelete} />,
      };
    }
  });

  const table = {
    columns: smDown ? sellsDataTableDataMobileSm.columns : mdDown ? sellsDataTableDataMobileMd.columns : lgDown ? sellsDataTableDataMobile.columns : sellsDataTableData.columns,
    rows: lgDown ? rowsMobile : rows,
  };

  //Effects
  useEffect(() => {
    //Companies by user
    getCompanies({ variables: { filters: { pagination: PAGINATION } } });
    //Rows
    const ClonedData = data ? [...data] : [];
    setRowsData(ClonedData.reverse());
  }, [window.location]);

  useEffect(() => {
    if (result) {
      const companieFilter = result?.data?.getCompaniesByUser?.data?.filter((item: any) => item?.company?.name === company?.name)[0];
      const document = companieFilter?.company?.documents.find((item: any) => item?.type === "NIT" || item?.type === "DUI" || item?.type === "OTRO" || item?.type === "PASAPORTE" || item?.type === "CARNET_RESIDENTE");
      setRequestorInfo({
        name: companieFilter?.company.name,
        docNumber: document?.documentNumber,
        docType: document?.type,
        idMainEntiry: companieFilter?.idMainEntity,
      });
    }
  }, [result]);

  useEffect(() => {
    if (dteInformation?.clientName?.label !== "" && dteInformation?.clientName?.id !== 0) {
      formik.setFieldValue("client", {
        label: dteInformation?.clientName?.label,
        id: dteInformation?.clientName?.id,
      });
    } else {
      formik.setFieldValue("client", null);
    }
  }, [dteInformation]);

  return (
    <>
      <SelectedItemsMenu selectedItems={selectedItems} handleOpenDelete={handleOpenDelete} setDteInformation={setDteInformation} setSelectedItems={setSelectedItems} />

      <Grid item xs={12}>
        <Card sx={{ padding: 5 }}>
          <MDBox>
            <MDTypography color="info" variant="h4">
              Administracion de Ventas
            </MDTypography>
            <MDTypography color="secondary" variant="subtitle2">
              Las ventas mostradas a continuación solo pertenece al periodo seleccionado
            </MDTypography>
          </MDBox>

          <Grid container gap={5} wrap="nowrap" direction={{ xs: "column", lg: "column" }}>
            <Grid item lg={12}>
              <MDBox mb={5}>
                <DataTable table={table} canSearch pagination={{ variant: "contained", color: "info" }}>
                  <Grid container alignItems="center" justifyContent="end">
                    <MDButton onClick={() => navigate("/ventas/NuevoDTE")} variant="gradient" size="medium" color="success">
                      Nueva venta
                    </MDButton>
                  </Grid>
                </DataTable>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      {dteInformation && (
        <Dialog
          open={openDelete}
          keepMounted
          onClose={() => {
            handleCloseDelete();
            formik.setFieldValue("nroDTE", "");
            formik.setFieldTouched("nroDTE", false);
          }}
        >
          {stepContent(activeStep)}
        </Dialog>
      )}
      <DialogEmail formik={formik} handleSubmit={sendEmail} handleClose={handleCloseEmail} loading={loadingEmail} open={open} />
    </>
  );
};

export default SellsTable;

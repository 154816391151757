// Graphql
import { useMutation } from "@apollo/client";

// Mutation
import { CREATE_FACTURA_EXPORTACION } from "apollo/mutations/dtes/createFacturaExportacion";

// Context
import { useAddSellContext } from "context/AddSellContext";
import { useSellsConfigContext } from "context/SellsConfigContext";

// Libs
import { FormikState } from "formik";
import toast from "react-hot-toast";

// Utils
import { capitalizeWords } from "utils/capitalizeWords";
import { getTime } from "utils/getTime";

// Types
import { ArticleTypes } from "types/Articulos";
import { SerializationKeys } from "types/apollo";
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";
import { PaymentConditions } from "types/payment-conditions";
import { ActiveValues } from "types/hooks/nuevoDte/activeValues";
import { SaleInformation } from "types/saleInformation";

interface Props {
  freeSale: boolean;
  setSaleInformation: React.Dispatch<React.SetStateAction<SaleInformation>>;
  handleClose: (value: ActiveValues) => void;
  handleOpen: (value: ActiveValues) => void;
}

export const useBulkFexSubmit = ({ freeSale = false, setSaleInformation, handleClose, handleOpen }: Props) => {
  // Context
  const {
    initialValues,
    global: { products, otherAssociatedDocuments, fleteFex, seguroFex, paymentMethods, saleDiscount, conditionPayment },
    resetGlobal,
  } = useAddSellContext();
  const { sellsConfig } = useSellsConfigContext();

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  // Mutation
  const [createFacturaExportacion, dataCreateFacturaExportacion] = useMutation(CREATE_FACTURA_EXPORTACION, { context });

  const handleBulkSubmitFexDte = async (values: AddSaleGeneralDocumentFilterValues, resetForm: (nextState?: Partial<FormikState<AddSaleGeneralDocumentFilterValues>>) => void) => {
    const companyExportador = {
      codIncoterms: values?.type !== ArticleTypes.SERVICIOS ? values?.incoterms?.codigo : null,
      recintoFiscal: values?.type !== ArticleTypes.SERVICIOS ? values?.recintoFiscal?.codigo : null,
      regimenExportacion: values?.type !== ArticleTypes.SERVICIOS ? values?.regimenExportacion?.codigo : null,
      fechaOperacion: `${values?.date} ${getTime()}`,
      idCostCenter: sellsConfig?.costCenter?.id,
      idSalePoint: sellsConfig?.selectedSalePoint?.id,
      tipoItem: values?.type,
    };
    const itemsConListaPrecio = products.map((product, index) => {
      return {
        cantidad: String(product?.quantity),
        idSalesPrices: product?.idSalePrice,
        montoDescu: Number(product?.productDiscount).toFixed(2),
        noGravado: "0",
        numItem: index + 1,
      };
    });
    const itemsNoRegistrados = products.map((product, index) => {
      return {
        // descripcion: product?.name,
        descripcion: `${product?.code ? `code ${product?.code} ${product?.name}` : `${product?.name}`}`,
        cantidad: String(product?.quantity),
        codigo: product?.code,
        montoDescu: Number(product?.productDiscount).toFixed(2),
        precioUni: String(product?.unitPrice),
        noGravado: "0",
        idUniMedida: product?.type !== ArticleTypes.SERVICIOS ? product?.measurement?.id : 56,
        numItem: index + 1,
      };
    });
    const otrosDocumentosAsociados =
      values?.otherAssociatedDocuments?.length > 0
        ? values?.otherAssociatedDocuments.map((document) => {
            return {
              descripcionDocAsociado: document?.description,
              documentoAsociado: document?.type,
              identificaciondDocAsociado: document?.document,
              modoTransporte: document?.type === "Transporte" ? Number(document?.typeOfTransport.codigo) : null,
              nombreConductor: document?.type === "Transporte" ? document?.NameDriver : null,
              numConductor: document?.type === "Transporte" ? document?.documentDriver : null,
              placaTransporte: document?.type === "Transporte" ? document?.vehiclePlate : null,
            };
          })
        : null;
    const resumen = {
      condicionOperacion: typeof values?.conditionPayment === "object" ? values?.conditionPayment?.value.toUpperCase() : values?.conditionPayment.toUpperCase(),
      descuento: Number(saleDiscount).toFixed(2) ?? "0.00",
      metodosPagos:
        conditionPayment === PaymentConditions.CREDITO
          ? null
          : paymentMethods.map((item) => ({
              amount: String(item?.amount),
              numeroReferencia: item?.numeroReferencia ?? null,
              paymentMethod: capitalizeWords(item?.selectedPaymentMethod?.name).replaceAll(" ", ""),
            })),
      /*  detalleCredito:
            conditionPayment === PaymentConditions.CREDITO
              ? {
                  periodo: values?.clientCompany?.creditPeriod,
                  plazo: values?.clientCompany?.plazoType,
                }
              : null, */
      observaciones: values?.extension?.observations,
      seguro: Number(seguroFex).toFixed(2) ?? "0.00",
      flete: Number(fleteFex).toFixed(2) ?? "0.00",
    };

    const vtasTercero =
      values?.ventasCuentasTerceros?.nit || values?.ventasCuentasTerceros?.name
        ? {
            nit: values?.ventasCuentasTerceros?.nit.replaceAll("-", ""),
            nombre: values?.ventasCuentasTerceros?.name,
          }
        : null;

    await createFacturaExportacion({
      variables: {
        input: {
          clienteReceptor: { idClientCompany: values?.clientCompany?.idClientCompany, notifyTo: values?.notifyTo },
          companyExportador,
          cuerpoDocumento: {
            itemsConListaPrecio: freeSale ? null : itemsConListaPrecio,
            itemsNoRegistrados: freeSale ? itemsNoRegistrados : null,
          },
          otrosDocumentosAsociados,
          resumen,
          vtasTercero,
        },
      },
    })
      .then(async ({ data }) => {
        if (data.createFacturaExportacion.selloRecibido && data.createFacturaExportacion.respuestaHacienda.completeResponse.estado === "PROCESADO") {
          toast.success("DTE agregado correctamente");
          setSaleInformation({
            successInfomation: data.createFacturaExportacion,
          });
          handleOpen(ActiveValues.ventaCompletada);
          await resetForm({
            values: initialValues,
          });
          handleClose(ActiveValues.paymentMethod);
          resetGlobal();
        } else if (data.createFacturaExportacion.respuestaHacienda.completeResponse.estado === "RECHAZADO") {
          toast.error(`Ocurrió un error al agregar venta ${data.createFacturaExportacion.respuestaHacienda.completeResponse.descripcionMsg}`);
          setSaleInformation({
            errorInformation: {
              mhObservations: data.createFacturaExportacion.respuestaHacienda.completeResponse.descripcionMsg,
              nameClient: values?.clientCompany?.name,
              generationCodeDte: data.createFacturaExportacion.respuestaHacienda.completeResponse.codigoGeneracion,
            },
          });
          handleClose(ActiveValues.paymentMethod);
          handleOpen(ActiveValues.ventaRechazada);
        }
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(`Ocurrió un error al agregar venta \n Por favor confirma que los campos son correctos e intente nuevamente o contacta a soporte`);
      });
  };
  return { handleBulkSubmitFexDte, dataCreateFacturaExportacion };
};

import React, { createContext, useState, useContext, useEffect, useMemo } from "react";

// Hooks
import { useLocalStorage } from "hooks/useLocalStorage";

// Constants
import { defaultValues as defaults } from "constants/sells";
import { IVA_AMOUNT } from "constants/finantial";

// Utils
import { filterGravados, sumAllGravadosAmounts } from "utils/calculations/gravados";
import { filterExentos, sumAllExentosAmounts } from "utils/calculations/exentos";
import { filterNoSujetos, sumAllNoSujetosAmounts } from "utils/calculations/noSujetos";
import { calculateIva } from "utils/calculations/calculateIva";
import { calculatePerception } from "utils/calculations/calculatePerception";
import { calculateRetention } from "utils/calculations/calculateRetention";
import { filterProductDiscount, sumAllProductDiscount } from "utils/calculations/productoDiscount";

// Types
import { Company } from "types/company";
import { PaymentMethod } from "types/paymentMethod";
import { ArticleTypes } from "types/Articulos";
import { legalRepresetantion } from "types/legalRepresentation";
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";
import { DataTableDTEObject } from "types/dataTableDteObject";

export interface Props {
  children: React.ReactNode;
}

interface SelectedPaymentMethodType {
  code: string;
  hasReferenceNumber: boolean;
  id: number;
  label: string;
  name: string;
}

export interface DataTablePaymentMethods extends PaymentMethod {
  amount?: number;
  paymentOperationType?: string;
  paymentMethodCode?: string;
  numeroReferencia?: string;
  selectedPaymentMethod?: SelectedPaymentMethodType;
}

export interface AddSellProviderProps {
  initialValues: AddSaleGeneralDocumentFilterValues;
  setInitialValues?: React.Dispatch<React.SetStateAction<any>>;
  global: AddSellPayload;
  setGlobal?: React.Dispatch<React.SetStateAction<AddSellPayload>>;
  company: Company;
  setCompany?: React.Dispatch<React.SetStateAction<Company>>;
  resetGlobal: React.Dispatch<React.SetStateAction<void>>;
}
export interface AddSellPayload extends AddSaleGeneralDocumentFilterValues {
  iva: number;
  retention: number;
  perception: number;
  retentionISR?: number;
  totalGravados: number;
  totalExentos: number;
  totalProductDiscount: number;
  totalNoSujetos: number;
  totalToPay: number;
  subTotal: number;
  products: DataTableDTEObject[];
  paymentMethods: DataTablePaymentMethods[];
  paymentMethodsFull: DataTablePaymentMethods[];
  conditionPayment: string;
  totalAmountOperation: number;
}

const AddSellContext = createContext<AddSellProviderProps>({
  initialValues: null,
  company: {},
  setCompany: () => {},
  resetGlobal: () => {},
  global: {
    paymentMethodsFull: [],
    recintoFiscal: null,
    regimenExportacion: null,
    incoterms: null,
    paymentMethods: [],
    products: [],
    idClient: null,
    iva: 0,
    retention: 0,
    productDiscount: "",
    noGravado: "",
    saleDiscount: "",
    fleteFex: "",
    seguroFex: "",
    totalProductDiscount: 0,
    perception: 0,
    totalGravados: 0,
    retentionISR: 0,
    totalExentos: 0,
    totalNoSujetos: 0,
    totalToPay: 0,
    subTotal: 0,
    conditionPayment: null,
    IvaDocumentType: null,
    totalAmountOperation: 0,
  },
});

export const useAddSellContext = () => {
  return useContext(AddSellContext);
};

export const AddSellContextProvider = ({ children }: Props) => {
  const defaultValues: AddSaleGeneralDocumentFilterValues = defaults;
  const [initialValues, setInitialValues] = useLocalStorage("addSell", defaultValues);
  const [company, setCompany] = useState({} as Company);

  const [global, setGlobal] = useState<AddSellPayload>({
    idClient: initialValues.idClient,
    iva: 0,
    retention: 0,
    perception: 0,
    retentionISR: 0,
    fleteFex: "",
    seguroFex: "",
    noGravado: "",
    recintoFiscal: null,
    regimenExportacion: null,
    incoterms: null,
    productDiscount: "",
    saleDiscount: "",
    totalGravados: 0,
    totalExentos: 0,
    totalNoSujetos: 0,
    totalProductDiscount: 0,
    totalToPay: 0,
    subTotal: 0,
    products: [],
    paymentMethods: [],
    paymentMethodsFull: [],
    conditionPayment: null,
    IvaDocumentType: null,
    totalAmountOperation: 0,
    clientCompany: null,
  });
  const totalGravados = useMemo(() => sumAllGravadosAmounts(filterGravados(global.products)), [global.products]);
  const totalExentos = useMemo(() => sumAllExentosAmounts(filterExentos(global.products)), [global.products]);
  const totalNoSujetos = useMemo(() => sumAllNoSujetosAmounts(filterNoSujetos(global.products)), [global.products]);
  const totalProductDiscount = useMemo(() => sumAllProductDiscount(filterProductDiscount(global.products)), [global.products]);

  // Calculations
  useEffect(() => {
    setGlobal({
      ...global,
      totalGravados: totalGravados,
      totalExentos: totalExentos,
      totalNoSujetos: totalNoSujetos,
      totalProductDiscount: totalProductDiscount,
      iva: global?.clientCompany?.isExent || global?.IvaDocumentType?.name_document !== "CCF" ? 0 : calculateIva(global.products),
      perception: totalGravados >= 100 && global?.IvaDocumentType?.name_document === "CCF" ? calculatePerception(totalGravados, company, global?.clientCompany) : 0,
      retention: totalGravados >= 100 && global?.IvaDocumentType?.name_document === "CCF" ? calculateRetention(totalGravados, company, global?.clientCompany) : 0,
      retentionISR:
        global?.clientCompany?.legalRepresentation &&
        global?.products &&
        global?.clientCompany?.legalRepresentation !== legalRepresetantion.PERSONA_NATURAL_NO_IVA &&
        company?.legalRepresentation === legalRepresetantion.PERSONA_NATURAL_IVA &&
        global.products.find((item) => item?.type !== ArticleTypes.BIENES && item?.type !== ArticleTypes.OTRO)
          ? global?.IvaDocumentType?.name_document === "FAC"
            ? (global?.products
                .filter((item) => item.articleType === ArticleTypes.SERVICIOS || item.articleType === ArticleTypes.AMBOS || item.productCodes.type === ArticleTypes.SERVICIOS || item.productCodes.type === ArticleTypes.AMBOS)
                .map((item) => item.totalPrice)
                .reduce((acc, current) => acc + current, 0) /
                Number((1 + IVA_AMOUNT).toFixed(2))) *
              0.1
            : global?.IvaDocumentType?.name_document === "CCF"
            ? global?.products
                .filter((item) => item.articleType === ArticleTypes.SERVICIOS || item.articleType === ArticleTypes.AMBOS || item.productCodes.type === ArticleTypes.SERVICIOS || item.productCodes.type === ArticleTypes.AMBOS)
                .map((item) => item.totalPrice)
                .reduce((acc, current) => acc + current, 0) * 0.1
            : 0
          : 0,
    });
  }, [global.products, global.clientCompany, company?.legalRepresentation]);

  // Calculate total to pay
  useEffect(() => {
    setGlobal({
      ...global,
      subTotal: global.totalGravados + global.totalExentos + global.totalNoSujetos + global.iva - global.totalProductDiscount - Number(global.saleDiscount ?? "") + Number(global.fleteFex ?? "") + Number(global.seguroFex ?? ""),
      totalAmountOperation: global.totalGravados + global.totalExentos + global.totalNoSujetos + global.iva,
      totalToPay:
        global.totalGravados +
        global.totalExentos +
        global.totalNoSujetos +
        global.iva +
        global.retention +
        global.perception -
        global.retentionISR -
        global.totalProductDiscount -
        Number(global.saleDiscount ?? "") +
        Number(global.fleteFex ?? "") +
        Number(global.seguroFex ?? ""),
    });
  }, [global.totalGravados, global.totalExentos, global.totalNoSujetos, global.iva, global.retention, global.perception, global.retentionISR, global.totalProductDiscount, global.saleDiscount, global.fleteFex, global.seguroFex]);

  const resetGlobal = () => {
    setGlobal({
      idClient: initialValues.idClient,
      iva: 0,
      retention: 0,
      perception: 0,
      totalGravados: 0,
      fleteFex: "",
      noGravado: "",
      seguroFex: "",
      totalExentos: 0,
      retentionISR: 0,
      productDiscount: "",
      saleDiscount: "",
      recintoFiscal: null,
      regimenExportacion: null,
      incoterms: null,
      totalNoSujetos: 0,
      totalProductDiscount: 0,
      totalToPay: 0,
      subTotal: 0,
      products: [],
      paymentMethods: [],
      paymentMethodsFull: [],
      conditionPayment: null,
      IvaDocumentType: null,
      totalAmountOperation: 0,
      clientCompany: null,
    });
  };
  const value = {
    initialValues,
    setInitialValues,
    global,
    setGlobal,
    company,
    setCompany,
    resetGlobal,
  };

  return <AddSellContext.Provider value={value}>{children}</AddSellContext.Provider>;
};

import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";
import Footer from "views/Footer";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";

// @mui material components
import { Autocomplete, Card, CircularProgress, FormControl, FormHelperText, Grid, Input, InputAdornment, InputLabel, Stack, SvgIcon, Switch, TextField, useMediaQuery, useTheme } from "@mui/material";

// React-Router-Dom
import { useLocation, useNavigate } from "react-router-dom";

//Graph
import { useLazyQuery, useMutation } from "@apollo/client";

//Formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Alert
import { toast } from "react-hot-toast";

// Data
import { genders } from "../crear/components/GeneralesEmployees";
import { afpTypes } from "../crear/components/PatronalesEmployees";

// Custom components
import CustomFieldError from "components/Global/Forms/CustomFieldError";
import DialogAddUser from "../components/dialogs/DialogAddUser";
import DialogDeleteUser from "../components/dialogs/DialogDeleteUser";

// Utils
import { departamentos, getMunicipios, paises } from "utils/getRegions";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

// Libs
import { format } from "fecha";

// Types

import { EmployeesValues } from "types/employeesComponentsProps";

// Queries
import { UPDATE_EMPLOYEE } from "apollo/queries/employees/updateEmployee";
import { UPDATE_EMPLOYEE_COMPANY } from "apollo/queries/employees/updateEmployeeCompany";
import { GET_EMPLOYEE } from "apollo/queries/employees/getEmployee";
import { UPDATE_DOCUMENT } from "apollo/queries/documents/updateDocument";
import { ADD_DOCUMENTS } from "apollo/queries/documents/addDocuments";
import { DELETE_DOCUMENT } from "apollo/queries/documents/deleteDocument";

// Validation
import { validate } from "../crear";

const validationSchema = Yup.object({
  firstName: Yup.string().required("El primer nombre es requerido").nullable(),
  secondName: Yup.string().nullable(),
  firstLastName: Yup.string().required("El primer apellido es requerido").nullable(),
  secondLastName: Yup.string().nullable(),
  // phone: Yup.string().required("El telefono es requerido").nullable(),
  emailNotifications: Yup.string().trim().email("Correo invalido").required("El correo es requerido").nullable(),
  gender: Yup.string().required("El genero es requerido").nullable(),
  pais: Yup.object({
    label: Yup.string(),
    id: Yup.string(),
  })
    .required("El pais es requerido")
    .nullable(),
  // departamento: Yup.string().required("El telefono es requerido").nullable(),
  // municipio: Yup.string().required("La razon de por que invalidas el DTE es requerida").nullable(),
  address: Yup.string().required("La dirreción es requerida").nullable(),
  checkInDate: Yup.string().required("La fecha de ingreso es requerida").nullable(),
  // checkOutDate: Yup.string().required("La razon de por que invalidas el DTE es requerida").nullable(),
  afpType: Yup.string().nullable(),
  // isss: Yup.string().required("El ISSS es requerido").nullable(),
  // nup: Yup.string().required("El NUP es requerido").nullable(),
});

function EditarEmpleados(): JSX.Element {
  // Theme
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));

  // React-Router-Dom
  const location = useLocation();
  const navigate = useNavigate();

  // State
  const [open, setOpen] = useState({
    add: false,
    delete: false,
  });

  // Queries and mutations
  const [getEmployee, { data, loading, error }] = useLazyQuery(GET_EMPLOYEE);
  const [updateEmployee, dataUpdateEmployee] = useMutation(UPDATE_EMPLOYEE);
  const [updateEmployeeCompany, dataUpdateEmployeeCompany] = useMutation(UPDATE_EMPLOYEE_COMPANY);
  const [updateDocument] = useMutation(UPDATE_DOCUMENT);
  const [addDocuments] = useMutation(ADD_DOCUMENTS);
  const [deleteDocument] = useMutation(DELETE_DOCUMENT);

  const formik = useFormik<EmployeesValues>({
    initialValues: {
      nit: "",
      dui: "",
      firstName: "",
      secondName: "",
      firstLastName: "",
      secondLastName: "",
      phone: "",
      emailNotifications: "",
      gender: "",
      hasUser: false,
      pais: null,
      departamento: null,
      municipio: null,
      address: "",
      checkInDate: "",
      checkOutDate: "",
      afpType: null,
      isss: "",
      nup: "",
    },
    validationSchema,
    validate,
    onSubmit: async (values) => {
      // Update Documents
      data.getEmployee.data.documents.map((item: any) => {
        if (item.type === "ISSS") {
          if (item.documentNumber !== values.isss && values.isss !== "") {
            updateDocument({
              variables: {
                idDocument: item.id,
                documentNumber: values.isss,
              },
            });
          }
        }
        if (item.type === "NIT") {
          if (item.documentNumber !== values.nit && values.nit !== "") {
            updateDocument({
              variables: {
                idDocument: item.id,
                documentNumber: values.nit,
              },
            });
          }
        }
        if (item.type === "DUI") {
          if (item.documentNumber !== values.dui && values.dui !== "") {
            updateDocument({
              variables: {
                idDocument: item.id,
                documentNumber: values.dui,
              },
            });
          }
        }
        if (item.type === "NUP") {
          if (item.documentNumber !== values.nup && values.nup !== "") {
            updateDocument({
              variables: {
                idDocument: item.id,
                documentNumber: values.nup,
              },
            });
          }
        }
      });
      //Add Documents
      const arr = [];
      const existeNit = data.getEmployee.data.documents.find((item: any) => item.type === "NIT");
      const existeNup = data.getEmployee.data.documents.find((item: any) => item.type === "NUP");
      const existeDui = data.getEmployee.data.documents.find((item: any) => item.type === "DUI");
      const existeIsss = data.getEmployee.data.documents.find((item: any) => item.type === "ISSS");
      if (values.dui !== "" && !existeDui) {
        arr.push({
          documentNumber: values.dui,
          type: "DUI",
        });
      }
      if (values.nit !== "" && !existeNit) {
        arr.push({
          documentNumber: values.nit,
          type: "NIT",
        });
      }
      if (values.nup !== "" && !existeNup) {
        arr.push({
          documentNumber: values.nup,
          type: "NUP",
        });
      }
      if (values.isss !== "" && !existeIsss) {
        arr.push({
          documentNumber: values.isss,
          type: "ISSS",
        });
      }
      if (arr.length > 0) {
        addDocuments({
          variables: {
            data: {
              idUser: data.getEmployee.data.id,
              userType: "EMPLOYEE",
              documents: arr,
            },
          },
        });
      }
      //Delete Documents
      if (values.dui === "" && existeDui) {
        deleteDocument({
          variables: {
            idDocument: existeDui.id,
          },
        });
      }
      if (values.isss === "" && existeIsss) {
        deleteDocument({
          variables: {
            idDocument: existeIsss.id,
          },
        });
      }
      if (values.nit === "" && existeNit) {
        deleteDocument({
          variables: {
            idDocument: existeNit.id,
          },
        });
      }
      if (values.nup === "" && existeNup) {
        deleteDocument({
          variables: {
            idDocument: existeNup.id,
          },
        });
      }
      Promise.all([
        updateEmployee({
          variables: {
            idEmployee: data.getEmployee.data.id,
            data: {
              afpType: values.afpType.toUpperCase() !== "" ? values.afpType.toUpperCase() : null,
              firstName: values.firstName,
              firstLastName: values.firstLastName,
              secondName: values?.secondName,
              secondLastName: values?.secondLastName,
              idRegion: !values?.municipio?.id ? Number(values?.pais?.id) : Number(values?.municipio?.id),
              address: values.address,
              genre: values.gender.toUpperCase(),
              isSupport: true,
            },
          },
        }),
        updateEmployeeCompany({
          variables: {
            idEmployeeCompany: data.getEmployee.data.idEmployeeCompany,
            data: {
              checkInDate: values.checkInDate,
              email: values.emailNotifications,
              checkOutDate: values.checkOutDate,
              phone: values.phone,
            },
          },
        }),
      ])
        .then((data) => {
          if (data[0].data.updateEmployee.isSuccess && data[1].data.updateEmployeeCompany.isSuccess) {
            toast.success("El empleado se actualizo correctamente");
            navigate("/contactos/empleados");
          } else {
            toast.error(`Hubo un error al actualizar el empleado `);
          }
        })
        .catch((e) => {
          toast.error(`Hubo un error al actualizar el empleado ${e}`);
        });
    },
  });

  const handleChangeFormat = (e: any) => {
    if (e.target.name === "NIT") {
      e.currentTarget.maxLength = 14;
      let string = e.currentTarget.value;
      string = string.replace(/\D/g, "");
      string = string.replace(/(\d{4})(\d{6})(\d{3})(\d{1})/, "$1-$2-$3-$4");
      formik.setFieldValue("nit", string);
    }
    if (e.target.name === "DUI") {
      e.currentTarget.maxLength = 9;
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{8})(\d{1})/, "$1-$2");
      formik.setFieldValue("dui", value);
    }
    if (e.target.name === "NRC") {
      e.currentTarget.maxLength = 8;
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d+)(\d{1})/, "$1-$2");
      formik.setFieldValue("nrc", value);
    }
  };
  const handleCloseAddUser = () => {
    setOpen((prev) => ({
      ...prev,
      add: false,
    }));
    formik.setFieldValue("hasUser", false);
  };
  const handleOpenAddUser = () => {
    setOpen((prev) => ({
      ...prev,
      add: true,
    }));
  };
  const handleCloseDeleteUser = () => {
    setOpen((prev) => ({
      ...prev,
      delete: false,
    }));
    formik.setFieldValue("hasUser", true);
  };
  const handleOpenDeleteUser = () => {
    setOpen((prev) => ({
      ...prev,
      delete: true,
    }));
  };
  const reFetch = () => {
    getEmployee({
      variables: {
        idEmployee: Number(location.state),
      },
    });
  };

  useEffect(() => {
    getEmployee({
      variables: {
        idEmployee: Number(location.state),
      },
    });
  }, [location.state]);

  useEffect(() => {
    if (data) {
      formik.setValues((prev) => ({
        ...prev,
        firstName: data.getEmployee.data?.firstName,
        secondName: data.getEmployee.data?.secondName,
        firstLastName: data.getEmployee.data?.firstLastName,
        secondLastName: data.getEmployee.data?.secondLastName,
        phone: data.getEmployee.data?.phone,
        emailNotifications: data.getEmployee.data?.emailNotifications,
        gender: capitalizeFirstLetter(data.getEmployee.data?.genre?.toLowerCase()),
        hasUser: data.getEmployee.data?.hasUser,
        pais: null,
        departamento: null,
        municipio: null,
        address: data.getEmployee.data?.address,
        checkInDate: format(new Date(data.getEmployee.data?.checkInDate), "YYYY-MM-DD"),
        checkOutDate: data.getEmployee.data?.checkOutDate !== null ? format(new Date(data.getEmployee.data?.checkOutDate), "YYYY-MM-DD") : null,
        afpType: capitalizeFirstLetter(data.getEmployee.data?.afpType?.toLowerCase()),
      }));
      data.getEmployee.data?.documents.map((item: any) => {
        if (item.type === "NUP") {
          formik.setFieldValue("nup", item.documentNumber);
        }
        if (item.type === "ISSS") {
          formik.setFieldValue("isss", item.documentNumber);
        }
        if (item.type === "DUI") {
          formik.setFieldValue("dui", item.documentNumber);
        }
      });
    }
  }, [data]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <MDBox display="flex" justifyContent="center" my={3}>
          <CircularProgress color="info" />
        </MDBox>
      ) : (
        <MDBox mt={5} mb={9} minHeight="100vh">
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox>
                    <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                      Informacion General
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Grid container spacing={lgDown ? 1 : 6}>
                          <Grid item xs={12} lg={3}>
                            <TextField
                              fullWidth
                              label="Primer Nombre*"
                              name="firstName"
                              variant="standard"
                              value={formik.values.firstName}
                              onBlur={() => formik.setFieldTouched("firstName", true)}
                              error={Boolean(formik.errors.firstName) && formik.touched.firstName}
                              helperText={!formik.touched.firstName || (formik.touched.firstName && !formik.errors.firstName) ? " " : formik.errors.firstName}
                              onChange={formik.handleChange}
                            />
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <TextField
                              fullWidth
                              label="Segundo Nombre"
                              name="secondName"
                              variant="standard"
                              value={formik.values.secondName}
                              onBlur={() => formik.setFieldTouched("secondName", true)}
                              error={Boolean(formik.errors.secondName) && formik.touched.secondName}
                              helperText={!formik.touched.secondName || (formik.touched.secondName && !formik.errors.secondName) ? " " : formik.errors.secondName}
                              onChange={formik.handleChange}
                            />
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <TextField
                              fullWidth
                              label="Primer Apellido*"
                              name="firstLastName"
                              variant="standard"
                              value={formik.values.firstLastName}
                              onBlur={() => formik.setFieldTouched("firstLastName", true)}
                              error={Boolean(formik.errors.firstLastName) && formik.touched.firstLastName}
                              helperText={!formik.touched.firstLastName || (formik.touched.firstLastName && !formik.errors.firstLastName) ? " " : formik.errors.firstLastName}
                              onChange={formik.handleChange}
                            />
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <TextField
                              fullWidth
                              label="Sugundo Apellido"
                              name="secondLastName"
                              variant="standard"
                              value={formik.values.secondLastName}
                              onBlur={() => formik.setFieldTouched("secondLastName", true)}
                              error={Boolean(formik.errors.secondLastName) && formik.touched.secondLastName}
                              helperText={!formik.touched.secondLastName || (formik.touched.secondLastName && !formik.errors.secondLastName) ? " " : formik.errors.secondLastName}
                              onChange={formik.handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={lgDown ? 1 : 6}>
                          <Grid item xs={12} lg={4}>
                            <Autocomplete
                              options={genders}
                              freeSolo
                              openOnFocus
                              value={formik.values.gender}
                              onChange={(_: any, newValue: string) => formik.setFieldValue("gender", newValue)}
                              onBlur={() => formik.setFieldTouched("gender", true)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Genero*"
                                  name="gender"
                                  error={Boolean(formik.errors.gender) && formik.touched.gender}
                                  helperText={!formik.touched.gender || (formik.touched.gender && !formik.errors.gender) ? " " : formik.errors.gender}
                                  variant="standard"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} lg={4}>
                            <TextField
                              fullWidth
                              label="Telefono"
                              name="phone"
                              variant="standard"
                              value={formik.values.phone}
                              onBlur={() => formik.setFieldTouched("phone", true)}
                              error={Boolean(formik.errors.phone) && formik.touched.phone}
                              helperText={!formik.touched.phone || (formik.touched.phone && !formik.errors.phone) ? " " : formik.errors.phone}
                              onChange={formik.handleChange}
                            />
                          </Grid>
                          <Grid item xs={12} lg={4}>
                            <TextField
                              fullWidth
                              label="Correo Electronico*"
                              name="emailNotifications"
                              variant="standard"
                              value={formik.values.emailNotifications}
                              onBlur={() => formik.setFieldTouched("emailNotifications", true)}
                              error={Boolean(formik.errors.emailNotifications) && formik.touched.emailNotifications}
                              helperText={!formik.touched.emailNotifications || (formik.touched.emailNotifications && !formik.errors.emailNotifications) ? " " : formik.errors.emailNotifications}
                              onChange={formik.handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={lgDown ? 1 : 6}>
                          <Grid item xs={12} lg={6}>
                            <FormControl size={`${mdDown ? "medium" : "small"}`} fullWidth variant="standard" error={Boolean(formik.errors.dui) && formik.touched.dui}>
                              <InputLabel>Documento Unico de Identidad</InputLabel>
                              <Input
                                endAdornment={
                                  <InputAdornment position="start">
                                    <SvgIcon viewBox="0 0 16 12">
                                      <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                                    </SvgIcon>
                                  </InputAdornment>
                                }
                                name="DUI"
                                onBlur={() => formik.setFieldTouched("dui", true)}
                                onChange={handleChangeFormat}
                                value={formik.values.dui}
                              />
                              <FormHelperText>{!formik.touched.dui || (formik.touched.dui && !formik.errors.dui) ? " " : formik.errors.dui}</FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <FormControl size={`${mdDown ? "medium" : "small"}`} fullWidth variant="standard" error={Boolean(formik.errors.nit) && formik.touched.nit}>
                              <InputLabel>Numero de Identificacion Tributaria</InputLabel>
                              <Input
                                name="NIT"
                                endAdornment={
                                  <InputAdornment position="start">
                                    <SvgIcon viewBox="0 0 16 12">
                                      <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                                    </SvgIcon>
                                  </InputAdornment>
                                }
                                onBlur={() => formik.setFieldTouched("nit", true)}
                                onChange={handleChangeFormat}
                                value={formik.values.nit}
                              />
                              <FormHelperText>{!formik.touched.nit || (formik.touched.nit && !formik.errors.nit) ? " " : formik.errors.nit}</FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={3}>
                      <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                        Crear de Usuario
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDTypography fontWeight="regular" color="secondary" sx={{ fontSize: "0.875rem" }}>
                        Al habilitar el usuario, el empleado podra ingresar al sistema e ingresar al sistema.
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Stack direction="row" justifyContent={{ xs: "flex-start", md: "flex-end" }} alignItems="center">
                        <MDTypography color="info" sx={{ fontSize: "1rem" }}>
                          Activar Usuario
                        </MDTypography>
                        <Switch
                          checked={formik.values.hasUser}
                          onChange={(e) => {
                            console.log();
                            formik.setFieldValue("hasUser", e.target.checked);
                            if (data?.getEmployee.data?.hasUser && !e.target.checked) {
                              handleOpenDeleteUser();
                            }
                            if (!data?.getEmployee.data?.hasUser && e.target.checked) {
                              handleOpenAddUser();
                            }
                          }}
                          name="Activar Usuario"
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox>
                    <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                      Datos Patronales
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={3} mb={1}>
                    <Grid item xs={12} md={2.4}>
                      <MDDatePicker
                        value={formik.values.checkInDate}
                        onChange={(date: string) => {
                          formik.setFieldValue("checkInDate", format(new Date(date), "YYYY-MM-DD"));
                        }}
                        options={{ maxDate: format(new Date(), "YYYY-MM-DD") }}
                        input={{
                          name: "checkInDate",
                          id: "checkInDate",
                          label: "Fecha de entrada",
                          variant: "standard",
                          sx: {
                            input: { paddingY: "1.04rem" },
                            width: "100%",
                          },
                        }}
                      >
                        <span onBlur={() => formik.setFieldTouched("checkInDate", true)} />
                      </MDDatePicker>
                      <CustomFieldError touched={formik.touched.checkInDate ? true : false} errorName={formik.errors.checkInDate && (formik.errors.checkInDate as string)} />
                    </Grid>
                    <Grid item xs={12} md={2.4}>
                      <MDDatePicker
                        value={formik.values.checkOutDate}
                        onChange={(date: string) => {
                          formik.setFieldValue("checkOutDate", format(new Date(date), "YYYY-MM-DD"));
                        }}
                        input={{
                          name: "checkOutDate",
                          id: "checkOutDate",
                          variant: "standard",
                          label: "Fecha de salida",
                          sx: {
                            input: { paddingY: "1.04rem" },
                            width: "100%",
                          },
                        }}
                      />
                      <CustomFieldError touched={formik.touched.checkOutDate ? true : false} errorName={formik.errors.checkOutDate && (formik.errors.checkOutDate as string)} />
                    </Grid>
                    <Grid item xs={12} md={2.4} mt={mdDown ? 0 : 3}>
                      <Autocomplete
                        freeSolo
                        openOnFocus
                        value={formik.values.afpType}
                        onChange={(e, value) => formik.setFieldValue("afpType", value)}
                        onBlur={() => formik.setFieldTouched("afpType", true)}
                        options={afpTypes}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tipo de AFP"
                            error={Boolean(formik.errors.afpType) && Boolean(formik.touched.afpType)}
                            helperText={!formik.touched.afpType || (formik.touched.afpType && !formik.errors.afpType) ? " " : `${formik.errors.afpType}`}
                            variant="standard"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={2.4} mt={mdDown ? 0 : 3.5}>
                      <FormControl size="small" fullWidth variant="standard" error={Boolean(formik.errors.nup) && formik.touched.nup}>
                        <InputLabel>Numero unico patronal</InputLabel>
                        <Input
                          endAdornment={
                            <InputAdornment position="start">
                              <SvgIcon viewBox="0 0 16 12">
                                <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                              </SvgIcon>
                            </InputAdornment>
                          }
                          name="nup"
                          onBlur={() => formik.setFieldTouched("nup", true)}
                          onChange={formik.handleChange}
                          value={formik.values.nup}
                        />
                        <FormHelperText>{!formik.touched.nup || (formik.touched.nup && !formik.errors.nup) ? " " : formik.errors.nup}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2.4} mt={mdDown ? 0 : 3}>
                      <FormControl size="medium" fullWidth variant="standard" error={Boolean(formik.errors.isss) && formik.touched.isss}>
                        <InputLabel>Numero ISSS</InputLabel>
                        <Input
                          endAdornment={
                            <InputAdornment position="start">
                              <SvgIcon viewBox="0 0 16 12">
                                <path d="M1.33333 0H14.6667C15.3667 0 16 0.633333 16 1.33333V10.6667C16 11.3667 15.3667 12 14.6667 12H1.33333C0.633333 12 0 11.3667 0 10.6667V1.33333C0 0.633333 0.633333 0 1.33333 0ZM9.33333 2V2.66667H14.6667V2H9.33333ZM9.33333 3.33333V4H14.3333H14.6667V3.33333H9.33333ZM9.33333 4.66667V5.33333H14V4.66667H9.33333ZM5.33333 7.27333C4 7.27333 1.33333 8 1.33333 9.33333V10H9.33333V9.33333C9.33333 8 6.66667 7.27333 5.33333 7.27333ZM5.33333 2C4.8029 2 4.29419 2.21071 3.91912 2.58579C3.54405 2.96086 3.33333 3.46957 3.33333 4C3.33333 4.53043 3.54405 5.03914 3.91912 5.41421C4.29419 5.78929 4.8029 6 5.33333 6C5.86377 6 6.37247 5.78929 6.74755 5.41421C7.12262 5.03914 7.33333 4.53043 7.33333 4C7.33333 3.46957 7.12262 2.96086 6.74755 2.58579C6.37247 2.21071 5.86377 2 5.33333 2Z" />
                              </SvgIcon>
                            </InputAdornment>
                          }
                          name="isss"
                          onBlur={() => formik.setFieldTouched("isss", true)}
                          onChange={formik.handleChange}
                          value={formik.values.isss}
                        />
                        <FormHelperText>{!formik.touched.isss || (formik.touched.isss && !formik.errors.isss) ? " " : formik.errors.isss}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox p={3}>
                  <MDBox>
                    <MDTypography color="info" variant="h4" fontWeight="bold" sx={{ fontSize: "1.25rem" }}>
                      Direccion
                    </MDTypography>
                  </MDBox>
                  <Grid container spacing={2} mb={1}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Direccion"
                        onBlur={() => formik.setFieldTouched("address", true)}
                        error={Boolean(formik.errors.address) && formik.touched.address}
                        value={formik.values.address}
                        helperText={!formik.touched.address || (formik.touched.address && !formik.errors.address) ? " " : `${formik.errors.address}`}
                        onChange={formik.handleChange}
                        name="address"
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={{ xs: 2, md: 9.5 }}>
                        <Grid item xs={12} md={formik.values.pais?.label === "EL SALVADOR" ? 4 : 12}>
                          <Autocomplete
                            id="pais"
                            openOnFocus
                            options={paises}
                            freeSolo
                            value={formik.values.pais}
                            onChange={(e, value) => formik.setFieldValue("pais", value)}
                            onBlur={() => formik.setFieldTouched("pais", true)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Pais"
                                error={Boolean(formik.errors.pais) && Boolean(formik.touched.pais)}
                                helperText={!formik.touched.pais || (formik.touched.pais && !formik.errors.pais) ? " " : `${formik.errors.pais}`}
                                variant="standard"
                              />
                            )}
                          />
                        </Grid>
                        {formik.values.pais?.label === "EL SALVADOR" && (
                          <Grid item xs={12} md={4}>
                            <Autocomplete
                              freeSolo
                              openOnFocus
                              onChange={(e, value) => formik.setFieldValue("departamento", value)}
                              value={formik.values.departamento}
                              onBlur={() => formik.setFieldTouched("departamento", true)}
                              options={departamentos}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Departamento"
                                  error={Boolean(formik.errors.departamento) && Boolean(formik.touched.departamento)}
                                  helperText={!formik.touched.departamento || (formik.touched.departamento && !formik.errors.departamento) ? " " : `${formik.errors.departamento}`}
                                  variant="standard"
                                />
                              )}
                            />
                          </Grid>
                        )}

                        {formik.values.pais?.label === "EL SALVADOR" && formik.values.departamento && (
                          <Grid item xs={12} md={4}>
                            <Autocomplete
                              freeSolo
                              openOnFocus
                              value={formik.values.municipio}
                              onChange={(e, value) => formik.setFieldValue("municipio", value)}
                              onBlur={() => formik.setFieldTouched("municipio", true)}
                              options={formik.values.departamento ? getMunicipios(formik.values.departamento.id) : []}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Municipio"
                                  error={Boolean(formik.errors.municipio) && Boolean(formik.touched.municipio)}
                                  helperText={!formik.touched.municipio || (formik.touched.municipio && !formik.errors.municipio) ? " " : `${formik.errors.municipio}`}
                                  variant="standard"
                                />
                              )}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item xs={5}>
                  <MDButton fullWidth variant="outlined" onClick={() => navigate("/contactos/empleados")} color="info">
                    Cancelar
                  </MDButton>
                </Grid>
                <Grid item xs={5}>
                  <MDButton
                    disabled={dataUpdateEmployee.loading || dataUpdateEmployeeCompany.loading}
                    onClick={() => {
                      // validateForm();
                      formik.handleSubmit();
                    }}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="info"
                  >
                    {dataUpdateEmployee.loading || dataUpdateEmployeeCompany.loading ? "Guardando..." : "Guardar"}
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      )}
      <DialogAddUser handleClose={handleCloseAddUser} open={open.add} employee={data?.getEmployee?.data} reFetch={reFetch} />
      <DialogDeleteUser handleClose={handleCloseDeleteUser} open={open.delete} employee={data?.getEmployee?.data} reFetch={reFetch} />
      <Footer />
    </DashboardLayout>
  );
}

export default EditarEmpleados;

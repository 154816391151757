import { useEffect, useRef, useContext } from "react";

// Styles
import "flatpickr/dist/themes/material_green.css";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBadge from "components/MDBadge";

// @mui material components
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";

// Custom Components
import CustomFieldError from "components/Global/Forms/CustomFieldError";
import ShortCutsLabels from "./ShortCutsLabels";

// Utils
import { validateEmailFormat } from "utils/validateEmailFormat";

// GraphQL
import { useQuery } from "@apollo/client";

// Queries
import { GET_IVA_DOCUMENTS } from "apollo/queries/dtes/getIvaDocuments";
import { GET_CLIENTS } from "apollo/queries/clients/getClients";

// Constants
import { PAGINATION } from "constants/pagination";

// Utils
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

// Context
import { useAddSellContext } from "context/AddSellContext";
import { KeyboardContext } from "context/KeyboardContext";
import { ShortcutContext } from "context/ShortcutContext";

// Libs
import { format } from "fecha";
import { toast } from "react-hot-toast";
import { useFormikContext } from "formik";

// Types
import { IvaDocument, IvaDocumentTypes } from "types/iva-document";
import { PaymentConditions } from "types/payment-conditions";
import { Client } from "types/clients";
import { SerializationKeys } from "types/apollo";
import { legalRepresetantion } from "types/legalRepresentation";
import { ClientCompany } from "types/documents";
import { OperationType } from "types/environment";
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";

interface GeneralDteFilters {
  emails?: string;
}

const GeneralDteFilters = ({ emails }: GeneralDteFilters) => {
  // Context
  const { global, setGlobal, resetGlobal, initialValues } = useAddSellContext();
  const { keys } = useContext(KeyboardContext);
  const { triggerListener, registerListener } = useContext(ShortcutContext);
  const { values, setFieldValue, setValues, handleChange, touched, setFieldTouched, errors } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  // Refs
  const notificationInput = useRef<HTMLDivElement>();
  const IvaDocument = useRef<HTMLDivElement>();
  const IdClient = useRef<HTMLDivElement>();
  const date = useRef<HTMLDivElement>();
  const paymentConditions = useRef<HTMLDivElement>();
  const input: any = document.getElementById("notifyTo");

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  // Queries
  const { data } = useQuery(GET_IVA_DOCUMENTS, {
    context,
    variables: { documentsFilters: { pagination: PAGINATION } },
  });
  const { data: dataClients } = useQuery(GET_CLIENTS, {
    context,
    variables: {
      clientFilters: {
        pagination: PAGINATION,
      },
    },
  });

  // Data
  const docTypes: any = data?.getIvaDocuments?.data?.length > 0 && data?.getIvaDocuments?.data?.filter((doc: IvaDocument) => doc.name_document === "FAC" || doc.name_document === "CCF" || doc.name_document === "FEX");

  // Functions
  const handleEmails = (e: any) => {
    if (e.key === "Enter") {
      const email = e.target.value;
      if (values.notifyTo?.includes(email)) {
        toast.error("El correo ingresado ya existe en la lista de notificaciones");
        setFieldValue("email", "");
      } else if (validateEmailFormat(email)) {
        setValues((prev) => ({
          ...prev,
          notifyTo: [...values.notifyTo, email],
          email: "",
        }));
        toast.success("Correo agregado correctamente");
      } else {
        setFieldValue("email", "");
        toast.error("El correo ingresado no es valido");
      }
    }
  };
  const handleSendEmails = (email: string) => {
    if (values.notifyTo?.includes(email)) {
      toast.error("El correo ingresado ya existe en la lista de notificaciones");
      setFieldValue("email", "");
    } else if (email && validateEmailFormat(email)) {
      setValues((prev) => ({
        ...prev,
        notifyTo: [...values.notifyTo, email],
        email: "",
      }));
      toast.success("Correo agregado correctamente");
    } else {
      setFieldValue("email", "");
      toast.error("El correo ingresado no es valido");
    }
  };
  const isOptionEqualToValue = (option: any, value: any) => option.id === value.id;
  const resetForm1 = () => {
    resetGlobal();
    setValues(() => ({
      ...initialValues,
    }));
  };

  // Effects
  useEffect(() => {
    setFieldValue("paymentMethods", null);
  }, [values?.conditionPayment]);
  useEffect(() => {
    registerListener(new Set(["control", "shift", "t"]), () => {
      IvaDocument.current.focus();
      IvaDocument.current.click();
    });
    registerListener(new Set(["control", "shift", "c"]), () => {
      IdClient.current.focus();
      IdClient.current.click();
    });
    registerListener(new Set(["control", "shift", "d"]), () => {
      const childNode = date?.current?.querySelector<HTMLInputElement>("#date");
      if (childNode) {
        childNode.focus();
        childNode.click();
      }
    });
    registerListener(new Set(["control", "shift", "n"]), () => {
      const childNode = notificationInput?.current?.querySelector<HTMLInputElement>("#notifyTo");
      if (childNode) {
        childNode.focus();
        childNode.click();
      }
    });
    registerListener(new Set(["control", "shift", "p"]), () => {
      paymentConditions.current.focus();
      paymentConditions.current.click();
    });

    return () => {
      // removeListener(keys);
    };
  }, [triggerListener]);
  useEffect(() => {
    if (emails) {
      if (!values.notifyTo.includes(emails)) {
        setValues((prev) => ({
          ...prev,
          notifyTo: [emails],
        }));
      }
    }
  }, [emails]);
  useEffect(() => {
    return () => {
      // Este código se ejecuta después de montar el componente
      // y no es necesario para el caso de limpieza.
      localStorage.removeItem("addSell");
    };
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Card sx={{ padding: 5 }}>
          <Grid container justifyContent="space-between" flexWrap={{ xs: "wrap", lg: "nowrap" }}>
            <MDTypography color="info" variant="h4" mb={2}>
              Generales del Documento Electronico Tributario
            </MDTypography>
            <MDBox display="flex" gap={2}>
              <MDButton color="info" size="small" onClick={() => window.open("/contactos/clientes", "_blank")}>
                Editar cliente
              </MDButton>
              <MDButton color="info" size="small" onClick={() => window.open("/contactos/clientes/crear", "_blank")}>
                Agregar cliente
              </MDButton>
            </MDBox>
          </Grid>
          <Grid container gap={7} my={5} mb={1}>
            <Grid container gap={3} wrap="nowrap" flexDirection={{ xs: "column", md: "row" }}>
              <Grid item width={{ sx: "100%", md: "50%" }}>
                <Autocomplete
                  id="IvaDocumentType"
                  noOptionsText="No hay tipos de documento tributario"
                  disablePortal
                  // isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                  fullWidth
                  openOnFocus
                  value={(values.IvaDocumentType && values.IvaDocumentType.extended_name_document) || null}
                  onChange={async (_event, newValue, _reason) => {
                    if (_reason === "clear") {
                      resetForm1();
                    } else {
                      const filteredDocType = docTypes.find((docType: IvaDocument) => docType.extended_name_document == newValue);
                      await setFieldValue("IvaDocumentType", filteredDocType ? filteredDocType : "");
                    }
                  }}
                  renderInput={(params) => (
                    <MDBox position="relative">
                      <TextField {...params} ref={IvaDocument} name="IvaDocumentType" data-testid="autocomplete-IvaDocumentType" label="Tipo de Documento Tributario*" tabIndex={1} onBlur={() => setFieldTouched("IvaDocumentType", true)} />
                      <ShortCutsLabels
                        keys={keys}
                        command={[
                          { key: "control", label: "CTRL" },
                          { key: "shift", label: "SHIFT" },
                          { key: "t", label: "T" },
                        ]}
                      />
                      <MDBox position="absolute">
                        <CustomFieldError touched={touched.IvaDocumentType ? true : false} errorName={errors.IvaDocumentType && (errors.IvaDocumentType as string)} />
                      </MDBox>
                    </MDBox>
                  )}
                  options={(docTypes && docTypes.length > 0 && docTypes?.map((item: IvaDocument) => item.extended_name_document)) || []}
                />
              </Grid>
              <Grid item width={{ sx: "100%", md: "50%" }}>
                <Autocomplete
                  id="idClient"
                  ref={IdClient}
                  disablePortal
                  getOptionDisabled={(option) => option?.label === "No hay clientes"}
                  openOnFocus
                  fullWidth
                  value={(values.clientCompany && { ...values.clientCompany, label: values?.clientCompany?.id + " - " + values?.clientCompany?.name }) || null}
                  onChange={async (event, newValue, reason) => {
                    if (reason === "clear") {
                      setValues((prev) => ({
                        ...prev,
                        clientCompany: null,
                        operation_type: null,
                      }));
                      setGlobal({ ...global, clientCompany: null });
                    } else {
                      const selectedClient: ClientCompany = dataClients?.getClients.data.find((client: Client) => client.id == newValue.id);
                      setValues((prev) => ({
                        ...prev,
                        clientCompany: selectedClient,
                        operation_type: {
                          label: OperationType.GRAVADAS,
                          value: Object.keys(OperationType).find((key) => (OperationType as any)[key] === OperationType.GRAVADAS),
                        },
                      }));
                      setGlobal({ ...global, clientCompany: selectedClient });
                      if (!selectedClient?.isActiveCredit) {
                        if (global.conditionPayment !== capitalizeFirstLetter(PaymentConditions.CONTADO.toLowerCase())) {
                          setGlobal((global) => {
                            return {
                              ...global,
                              conditionPayment: capitalizeFirstLetter(PaymentConditions.CONTADO.toLowerCase()),
                            };
                          });
                        }
                        if (
                          typeof values.conditionPayment === "object"
                            ? values.conditionPayment?.label !== capitalizeFirstLetter(PaymentConditions.CONTADO.toLowerCase())
                            : values.conditionPayment !== capitalizeFirstLetter(PaymentConditions.CONTADO.toLowerCase())
                        ) {
                          await setFieldValue("conditionPayment", { label: capitalizeFirstLetter(PaymentConditions.CONTADO.toLowerCase()), value: PaymentConditions.CONTADO });
                        }
                      }
                      if (selectedClient?.isExent) {
                        await setFieldValue("operation_type", {
                          label: OperationType.EXENTAS,
                          value: Object.keys(OperationType).find((key) => (OperationType as any)[key] === OperationType.EXENTAS),
                        });
                      }
                    }
                  }}
                  isOptionEqualToValue={isOptionEqualToValue}
                  renderInput={(params) => (
                    <MDBox position="relative">
                      <TextField data-testid="autocomplete-idClient" onBlur={() => setFieldTouched("clientCompany", true)} {...params} name="clientCompany" label="Nombre, DUI o NRC del Cliente" />
                      <ShortCutsLabels
                        keys={keys}
                        command={[
                          { key: "control", label: "CTRL" },
                          { key: "shift", label: "SHIFT" },
                          { key: "c", label: "C" },
                        ]}
                      />
                      <MDBox position="absolute">
                        <CustomFieldError touched={touched.clientCompany ? true : false} errorName={errors.clientCompany && (errors.clientCompany as string)} />
                      </MDBox>
                    </MDBox>
                  )}
                  options={
                    dataClients?.getClients?.data.length > 0
                      ? values.IvaDocumentType?.extended_name_document === IvaDocumentTypes.EXTENDED_CCF
                        ? dataClients?.getClients?.data
                            .filter((item: Client) => item.legalRepresentation !== legalRepresetantion.PERSONA_NATURAL_NO_IVA)
                            .map((item: Client) => {
                              return { ...item, label: item.id + " - " + item.name };
                            })
                        : dataClients?.getClients?.data.map((item: Client) => {
                            return { ...item, label: item.id + " - " + item.name };
                          })
                      : [
                          {
                            label: "No hay clientes",
                          },
                        ]
                  }
                />
              </Grid>
            </Grid>

            <Grid container gap={3} wrap="nowrap" justifyContent="center" flexDirection={{ xs: "column", md: "row" }}>
              <Grid id="flex" width={{ sx: "100%", md: "30%", lg: "20%" }}>
                <MDBox position="relative">
                  <MDDatePicker
                    value={values.date}
                    onChange={(date: string) => {
                      setFieldValue("date", format(new Date(date), "YYYY-MM-DD"));
                    }}
                    input={{
                      ref: date,
                      name: "date",
                      id: "date",
                      placeholder: "Fecha del Documento",
                      sx: {
                        input: { paddingY: "1.04rem" },
                        width: "100%",
                      },
                    }}
                  />
                  <ShortCutsLabels
                    keys={keys}
                    command={[
                      { key: "control", label: "CTRL" },
                      { key: "shift", label: "SHIFT" },
                      { key: "d", label: "D" },
                    ]}
                  />
                </MDBox>
              </Grid>
              <Grid id="flex" width={{ sx: "100%", md: "30%", lg: "20%" }}>
                <MDBox position="relative">
                  <MDInput
                    id="notifyTo"
                    type="email"
                    name="email"
                    ref={notificationInput}
                    value={values.email}
                    onChange={handleChange}
                    label="Agregar Correos a Notificar"
                    fullWidth={true}
                    sx={{ input: { paddingY: "1.04rem" } }}
                    onKeyPress={handleEmails}
                  />
                  <MDBox position="absolute" display="flex" alignItems="center" top="0" right={10} height="100%" sx={{ cursor: "pointer" }}>
                    <SendIcon
                      onClick={() => {
                        handleSendEmails(input.value);
                      }}
                    />
                  </MDBox>
                  <ShortCutsLabels
                    keys={keys}
                    command={[
                      { key: "control", label: "CTRL" },
                      { key: "shift", label: "SHIFT" },
                      { key: "n", label: "N" },
                    ]}
                  />
                </MDBox>
              </Grid>
              <Grid item width={{ sx: "100%", md: "30%", lg: "20%" }}>
                <Autocomplete
                  id="conditionPayment"
                  ref={paymentConditions}
                  openOnFocus
                  disabled={values.clientCompany !== null ? !values.clientCompany?.isActiveCredit : false}
                  disablePortal
                  fullWidth={true}
                  isOptionEqualToValue={(value, option) => typeof value === "object" && typeof option === "object" && value.label === option.label}
                  value={values.conditionPayment}
                  options={[
                    { label: "Contado", value: PaymentConditions.CONTADO },
                    { label: "Credito", value: PaymentConditions.CREDITO },
                    { label: "Otro", value: PaymentConditions.OTRO },
                  ]}
                  onChange={async (event, newValue, reason) => {
                    if (reason === "clear") {
                      setFieldValue("conditionPayment", null);
                      setGlobal((global) => {
                        return {
                          ...global,
                          conditionPayment: null,
                        };
                      });
                    } else {
                      setFieldValue("conditionPayment", newValue);
                      setGlobal((global) => {
                        return {
                          ...global,
                          conditionPayment: typeof newValue === "object" && newValue?.value,
                        };
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <MDBox position="relative">
                      <TextField {...params} label="Condicion de Pago" name="conditionPayment" data-testid="autocomplete-conditionPayment" />
                      <ShortCutsLabels
                        keys={keys}
                        command={[
                          { key: "control", label: "CTRL" },
                          { key: "shift", label: "SHIFT" },
                          { key: "p", label: "P" },
                        ]}
                      />
                      <MDBox position="relative">
                        <CustomFieldError touched={touched.conditionPayment ? true : false} errorName={errors.conditionPayment && (errors.conditionPayment as string)} />
                      </MDBox>
                    </MDBox>
                  )}
                />
              </Grid>
            </Grid>

            <Grid container gap={4} flexDirection="row">
              {values.notifyTo?.length > 0 ? (
                values.notifyTo.map((email, index) => (
                  <MDBox key={index} position="relative">
                    <CancelIcon
                      sx={{
                        position: "absolute",
                        top: "-12px",
                        left: "-12px",
                        zIndex: 10,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setValues((prev) => ({
                          ...prev,
                          notifyTo: prev.notifyTo.filter((item) => item !== email),
                        }));
                      }}
                    />
                    <MDBadge color={validateEmailFormat(email) ? "success" : "error"} container badgeContent={email}></MDBadge>
                  </MDBox>
                ))
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};
export default GeneralDteFilters;

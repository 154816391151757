import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import { CircularProgress } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Context
import { useUserContext } from "context/UserContext";

// Custom components
import StateDropdown from "./StateDropdown";
import EventsDropDown from "./EventsDropDown";

// View
import DataTable from "views/Tables/DataTable";

// Context
import { useSellsConfigContext } from "context/SellsConfigContext";

// GraphQL
import { gql, useMutation, useLazyQuery, useQuery } from "@apollo/client";

// Queries and mutations
import { GET_DTE_STATISTICS } from "apollo/queries/dtes/getDteStatistics";
import { GET_DTE_EMITTED_ON_TESTING_MH } from "apollo/queries/dtes/getDteEmittedOnTestingMh";
import { GENERATE_AUTO_DTES } from "apollo/mutations/configuration/generateAutoDtes";
import { CHANGE_DTE_ENVIROMENT } from "apollo/mutations/configuration/changeDteEnviroment";
import { CREATE_TEST_FACTURA_EXPORTACION } from "apollo/mutations/configuration/createTestFacturaExportacion";
import { GENERATE_INVALIDATION_EVENTS } from "apollo/mutations/configuration/generateTestsInvalidationEvents";
import { GENERATE_CONTIGENCY_EVENTS } from "apollo/mutations/configuration/generateContingencyEvents";
import { GET_COSTS_CENTERS } from "apollo/queries/getCostsCenters";

// Libs
import toast from "react-hot-toast";
import { useFormik } from "formik";

// Utils
import { getEnvironmentSubmit } from "utils/getEnvironmentSubmit";

// Types
import { DropdownOptions, Environment } from "types/environment";
import { IvaDocument, IvaDocumentTypes } from "types/iva-document";
import { CostCenter } from "types/sells";
import { PAGINATION } from "constants/pagination";
import { useCompanyContext } from "context/CompanyContext";

type getDteStatisticsData = {
  getDteStatistics: {
    data: IvaDocument[];
  };
};
type SelectedEnvChange = {
  label: string;
  value: string;
};
// GraphQl
const GET_ARTICLES = gql`
  query GET_ARTICLES($articlesFilters: ArticlesQueryFilterDti!) {
    getArticles(articlesFilters: $articlesFilters) {
      isSuccess
      data {
        id
        name
        code
        barCode
        tags {
          id
          name
        }
        salesPrices {
          id
          salePrice
          isDefault
          priceList {
            id
            name
          }
        }
        storages {
          articleStock
          skul
        }
      }
      message {
        detail
        code
        message
      }
    }
  }
`;

const DTES = () => {
  // Context
  const { user } = useUserContext();
  const { infoEmployeeDocuments } = useCompanyContext();
  const { sellsConfig, setSellsConfig, resetSellsConfig } = useSellsConfigContext();

  // GraphQL
  const [getDteStatistics, { data, loading, refetch }] = useLazyQuery<getDteStatisticsData>(GET_DTE_STATISTICS);
  const [getDteEmittedOnTestingMh, { data: emitted_dte, loading: loading_emitted }] = useLazyQuery(GET_DTE_EMITTED_ON_TESTING_MH);
  const [getArticles, dataGetArticles] = useLazyQuery(GET_ARTICLES);
  const [changeDteEnvironment, { data: newData, loading: newLoading, error: newError }] = useMutation(CHANGE_DTE_ENVIROMENT);
  const [generateAutoDtes, { loading: loadingAutoDtes }] = useMutation(GENERATE_AUTO_DTES);
  const [createTestFacturaExportacion, { loading: loadingTestFex }] = useMutation(CREATE_TEST_FACTURA_EXPORTACION);
  const [generateTestsInvalidationEvents, { loading: loadingTestInvalidation }] = useMutation(GENERATE_INVALIDATION_EVENTS);
  const [generateContingencyEvents, { loading: loadingTestContingecy }] = useMutation(GENERATE_CONTIGENCY_EVENTS);
  const { data: costsCenters } = useQuery(GET_COSTS_CENTERS, { variables: { pagination: PAGINATION } });

  // States
  const [rowsData, setRowsData] = useState([]);
  const [openModal, setOpenModal] = useState({ modal: null, id: null, row: null });
  const [selectedEnvChange, setSelectedEnvChange] = useState<SelectedEnvChange>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isDte, setIsDte] = useState(true);

  const formik = useFormik({
    initialValues: {
      product: null,
    },
    onSubmit: async (values) => {
      const idPriceList = values.product?.salesPrices.find((item: any) => item.isDefault === true)?.priceList?.id;
      const articlePrice = values.product?.salesPrices.find((item: any) => item.isDefault === true)?.salePrice;
      await generateAutoDtes({
        variables: {
          idPriceList,
          articlePrice,
          idIvadocument: openModal?.row?.id,
          idEmployee: infoEmployeeDocuments.idEntity,
          idArticle: values.product?.id,
          quantity: 1,
        },
      })
        .then((res) => {
          setActiveStep(0);
          setOpenModal({ modal: null, id: null, row: null });
          formik.setFieldValue("product", null);
          formik.setFieldTouched("product", false);
          res.data.generateAutoDtes.isSuccess && setOpenModal({ modal: null, id: null, row: null });
          res.data.generateAutoDtes.isSuccess && toast.success(`Se han generado correctamente los documentos.`);
          res.data.generateAutoDtes.isSuccess === false && toast.error(`Ha ocurrido un error al generar los documentos. ${res.data.generateAutoDtes.message.detail}`);
        })
        .catch((err) => {
          console.log("error", err);
          toast.error(`Ha ocurrido un error al generar los documentos.`);
        });
    },
  });

  const handleSelect = (rowId: number, value: DropdownOptions, row: typeof data) => {
    if (value === DropdownOptions.SELECT_ENV) {
      setOpenModal({ modal: value, id: rowId, row: row });
    } else if (value === DropdownOptions.AUTOMATIC_TEST_MH) {
      setOpenModal({ modal: value, id: rowId, row: row });
    }
  };

  const getNumber = (value: number, document: string) => {
    if (document === "FAC") {
      const number = 90 - value;
      return number < 0 ? 0 : number;
    } else {
      const number = 75 - value;
      return number < 0 ? 0 : number;
    }
  };

  const handleSubmitFexAutoDte = async () => {
    await createTestFacturaExportacion({
      variables: {
        input: {
          idSalePoint: sellsConfig.selectedSalePoint?.id,
          idCostCenter: sellsConfig.costCenter?.id,
        },
      },
    })
      .then((responseCreateTestFacturaExportacion) => {
        if (responseCreateTestFacturaExportacion.data?.createTestFacturaExportacion?.isSuccess) {
          setOpenModal({ modal: null, id: null, row: null });
          toast.success(`Se han generado correctamente los documentos.`);
        } else {
          toast.error(`Ha ocurrido un error al generar los documentos. ${responseCreateTestFacturaExportacion.data?.createTestFacturaExportacion?.message.detail}`);
        }
      })
      .catch((err) => {
        err.graphQLErrors.map((item: any) => {
          const resMessage = item?.extensions.response.message
            .map((item: string) => {
              return `${item}
            `;
            })
            .join(" ");
          toast.error(
            `Hubo un error ${item?.extensions.code} - ${item?.extensions.response.statusCode}: 
            ${resMessage}`
          );
        });
      });
  };

  const handleSubmitEvents = async (value: DropdownOptions) => {
    if (value === DropdownOptions.GENERATE_INVALIDATION_EVENTS) {
      await generateTestsInvalidationEvents()
        .then((resGenerateTestsInvalidationEvents) => {
          if (resGenerateTestsInvalidationEvents.data?.generateTestsInvalidationEvents?.isSuccess) {
            toast.success(`Se han generado correctamente los eventos.`);
          } else {
            toast.error(`Ha ocurrido un error al generar los eventos. ${resGenerateTestsInvalidationEvents.data?.generateTestsInvalidationEvents?.message.detail}`);
          }
        })
        .catch((err) => {
          err.graphQLErrors.map((item: any) => {
            const resMessage = item?.extensions.response.message
              .map((item: string) => {
                return `${item}
            `;
              })
              .join(" ");
            toast.error(
              `Hubo un error ${item?.extensions.code} - ${item?.extensions.response.statusCode}: 
            ${resMessage}`
            );
          });
        });
    } else if (value === DropdownOptions.GENERATE_CONTINGENCY_EVENTS) {
      await generateContingencyEvents()
        .then((resGenerateContingencyEvents) => {
          if (resGenerateContingencyEvents.data?.generateContingencyEvents?.isSuccess) {
            toast.success(`Se han generado correctamente los eventos.`);
          } else {
            toast.error(`Ha ocurrido un error al generar los eventos. ${resGenerateContingencyEvents.data?.generateContingencyEvents?.message.detail}`);
          }
        })
        .catch((err) => {
          err.graphQLErrors.map((item: any) => {
            const resMessage = item?.extensions.response.message
              .map((item: string) => {
                return `${item}
            `;
              })
              .join(" ");
            toast.error(
              `Hubo un error ${item?.extensions.code} - ${item?.extensions.response.statusCode}: 
            ${resMessage}`
            );
          });
        });
    }
  };

  // Efects
  useEffect(() => {
    if (data) {
      setRowsData(data?.getDteStatistics.data);
    }
  }, [data]);

  useEffect(() => {
    if (newError) {
      toast.error("La petición para modificar el estado no pudo ser enviada.");
    }
  }, [newError]);

  useEffect(() => {
    if (openModal.modal === DropdownOptions.AUTOMATIC_TEST_MH && openModal?.id) {
      getDteEmittedOnTestingMh({ variables: { idDteElectronicBilling: openModal.id } });
    }
  }, [openModal]);

  useEffect(() => {
    if (isDte) {
      getDteStatistics();
    }
  }, [isDte]);

  // Data
  const rows = rowsData?.map((item) => {
    return {
      name: item.extended_name_document,
      idDteEnvironment: item.idDteEnvironment,
      state: item.billingEnvironment,
      menu: <StateDropdown id={item.idDteEnvironment} row={item} state={item.billingEnvironment} handleSelect={handleSelect} />,
    };
  });

  const table = {
    columns: [
      { Header: "Nombre del documento", accessor: "name", width: "45%" },
      { Header: "Emisión de documentos", accessor: "state", width: "45%" },
      { Header: "Menú", accessor: "menu", width: "10%", hiddeSort: true, align: "center" },
    ],
    rows: rows || [],
  };
  const tableInfo = {
    dtes: {
      title: "Estados de las emiciones de los DTES",
      subTitle: "Aqui podras ver bajo que condiciones se esta emitiendo el estado de los Documentos Tributarios Electronicos. Recuerda que la Autorizacion para emitir cada uno es por cada DTE.",
    },
    events: {
      title: "Eventos de los DTES",
      subTitle: "Aqui podras emitir los eventos con respecto a los DTES",
      table: {
        columns: [
          { Header: "Nombre del evento", accessor: "name", width: "45%" },
          { Header: "Menú", accessor: "menu", width: "10%", hiddeSort: true, align: "center" },
        ],
        rows: [
          {
            name: "Evento de Invalidacion",
            menu: loadingTestInvalidation ? <CircularProgress color="inherit" size={20} /> : <EventsDropDown option={DropdownOptions.GENERATE_INVALIDATION_EVENTS} handleSubmit={handleSubmitEvents} />,
          },
          {
            name: "Evento de Contingencia",
            menu: loadingTestContingecy ? <CircularProgress color="inherit" size={20} /> : <EventsDropDown option={DropdownOptions.GENERATE_CONTINGENCY_EVENTS} handleSubmit={handleSubmitEvents} />,
          },
        ],
      },
    },
  };

  return (
    <>
      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center" gap={4}>
          <Grid item xs={12} lg={10}>
            <Card>
              <MDBox p={4}>
                <MDBox mb={5}>
                  <MDTypography color="info" variant="h4">
                    {isDte ? tableInfo.dtes.title : tableInfo.events.title}
                  </MDTypography>
                  <MDTypography color="secondary" variant="subtitle2">
                    {isDte ? tableInfo.dtes.subTitle : tableInfo.events.subTitle}
                  </MDTypography>
                </MDBox>
                <Grid container gap={5} wrap="nowrap" direction={{ xs: "column", lg: "row" }}>
                  <Grid item lg={12}>
                    <MDBox mb={5}>
                      <DataTable table={isDte ? table : tableInfo.events.table} canSearch pagination={{ variant: "contained", color: "info" }}>
                        <MDButton onClick={() => setIsDte(!isDte)} variant="contained" size="medium" color="info">
                          {isDte ? "Generar Eventos" : "Generar Dtes"}
                        </MDButton>
                      </DataTable>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* 
       Modal cambiar entorno
      */}
      <Modal open={openModal.modal === DropdownOptions.SELECT_ENV ? true : false} onClose={() => setOpenModal({ modal: null, id: null, row: null })} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Card
          sx={{
            overflowY: "auto",
            padding: 3,
            "@media (max-width:600px)": {
              width: "90%",
            },
            "@media (min-width:600px)": {
              width: "60%",
            },
            maxHeight: "90vh",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
        >
          <MDBox p={3} display="flex" flexDirection="column" gap={5}>
            <MDBox>
              <MDTypography color="info" variant="h4" fontWeight="bold">
                {openModal?.row?.extended_name_document}
              </MDTypography>
              <p>
                El entorno actual es{" "}
                <MDTypography color="warning" variant="span" fontWeight="bold" mb={2}>
                  {openModal?.row?.billingEnvironment}
                </MDTypography>
              </p>
            </MDBox>
            <MDBox>
              <p>Por favor selecciona el entorno con el que vas a trabajar</p>
              <Autocomplete
                onChange={(event, value: SelectedEnvChange) => {
                  setSelectedEnvChange(value);
                }}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => <TextField {...params} name="EntornoChange" data-testid="autocomplete-change-entorno" label="Entorno" />}
                options={[
                  { label: Environment.PruebasInternas, value: Object.keys(Environment).find((key) => (Environment as any)[key] === Environment.PruebasInternas) },
                  { label: Environment.PruebasMH, value: Object.keys(Environment).find((key) => (Environment as any)[key] === Environment.PruebasMH) },
                  { label: Environment.EmisionesReales, value: Object.keys(Environment).find((key) => (Environment as any)[key] === Environment.EmisionesReales) },
                ]}
              />
            </MDBox>

            <MDBox display="flex" justifyContent="space-between">
              <MDButton variant="outlined" color="info" onClick={() => setOpenModal({ modal: null, id: null, row: null })}>
                Cancelar
              </MDButton>
              <MDButton
                onClick={() => {
                  if (selectedEnvChange?.value && selectedEnvChange?.label !== openModal?.row?.billingEnvironment) {
                    changeDteEnvironment({ variables: { idDteEnvironment: openModal?.row?.idDteEnvironment, environment: getEnvironmentSubmit(selectedEnvChange.label) } })
                      .then((res) => {
                        setOpenModal({ modal: null, id: null, row: null });
                        if (res.data.changeDteEnvironment.isSuccess) {
                          refetch();
                          toast.success(`El entorno se ha cambiado correctamente`);
                        } else {
                          toast.error(`Ha ocurrido un error al cambiar el entorno, Error: ${res.data.changeDteEnvironment.message.detail}`);
                        }
                      })
                      .catch((err) => {
                        console.log("error", err);
                        toast.error(`Ha ocurrido un error al cambiar el entorno`);
                      });
                  } else if (selectedEnvChange?.label === openModal?.row?.billingEnvironment) {
                    toast.success(`El entorno seleccionado es el mismo que el actual`);
                  }
                }}
                variant="contained"
                color="info"
              >
                {newLoading ? <CircularProgress color="inherit" size={20} /> : "Aceptar"}
              </MDButton>
            </MDBox>
          </MDBox>
        </Card>
      </Modal>
      {/* 
       Modal dtes automaticos
      */}
      <Modal
        open={openModal.modal === DropdownOptions.AUTOMATIC_TEST_MH ? true : false}
        onClose={() => {
          setActiveStep(0);
          formik.setFieldValue("product", null);
          formik.setFieldTouched("product", false);
          setOpenModal({ modal: null, id: null, row: null });
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          sx={{
            overflowY: "auto",
            padding: 3,
            "@media (max-width:600px)": {
              width: "90%",
            },
            "@media (min-width:600px)": {
              width: "60%",
            },
            maxHeight: "90vh",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
        >
          {activeStep === 0 ? (
            <MDBox p={3}>
              {!loading_emitted ? (
                <>
                  <MDBox mb={4}>
                    <MDTypography color="info" variant="h4" fontWeight="bold" mb={2}>
                      Pruebas automáticas con el Ministerio de Hacienda
                    </MDTypography>
                    <MDTypography color="warning" variant="body" fontWeight="bold" mb={2}>
                      {openModal?.row?.extended_name_document}
                    </MDTypography>
                    <p>
                      Haz emitido <b>{emitted_dte?.getDteEmittedOnTestingMh?.data?.quantity > 1 ? `${emitted_dte?.getDteEmittedOnTestingMh?.data?.quantity} documentos` : `${emitted_dte?.getDteEmittedOnTestingMh?.data?.quantity} documento`}</b> en el
                      entorno de pruebas con el ministerio de hacienda. <br />
                      {emitted_dte?.getDteEmittedOnTestingMh?.data?.quantity > 1 ? "Te faltan" : "Te falta"} <b>{getNumber(parseInt(emitted_dte?.getDteEmittedOnTestingMh?.data?.quantity), openModal?.row?.name_document)}</b> para poder pasar a emitir
                      DTEs reales.
                    </p>
                  </MDBox>
                  <MDBox>
                    <Grid container gap={1} justifyContent="center">
                      <Grid item xs={12} mb={1}>
                        <MDTypography variant="h4" color="info">
                          ¿Desde dónde facturas?
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} lg={5} mb={2}>
                        <Autocomplete
                          id="SellsRoom"
                          noOptionsText="No hay salas de venta"
                          disablePortal
                          fullWidth
                          renderInput={(params: any) => <TextField {...params} name="SellsRoom" data-testid="autocomplete-SellsRoom" label="Sala de ventas" />}
                          onChange={async (event: any, newValue: any, reason: any) => {
                            if (reason === "clear") {
                              resetSellsConfig();
                              return;
                            }
                            const costCenter = costsCenters?.getCostsCenters?.data.find((center: CostCenter) => center.name === newValue);
                            setSellsConfig({
                              ...sellsConfig,
                              costCenter,
                              salePoints: costCenter.salePoints,
                            });
                          }}
                          options={costsCenters?.getCostsCenters?.data.map((item: CostCenter) => item.name) || []}
                        />
                      </Grid>
                      <Grid item xs={12} lg={5} mb={2}>
                        <Autocomplete
                          id="SellsPoint"
                          noOptionsText="No hay puntos de venta"
                          disablePortal
                          fullWidth
                          renderInput={(params: any) => <TextField {...params} name="SellsPoint" data-testid="autocomplete-SellsPoint" label="Puntos de Ventas" />}
                          onChange={async (event: any, newValue: any) => {
                            const salePoint = sellsConfig.salePoints.find((center: any) => center.name === newValue);
                            setSellsConfig({ ...sellsConfig, selectedSalePoint: salePoint });
                          }}
                          options={(sellsConfig && sellsConfig.salePoints && sellsConfig.salePoints.length > 0 && sellsConfig?.salePoints.map((item) => item.name)) || []}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                  <MDBox mb={4}>
                    <MDTypography textAlign="center" variant="body1" fontWeight="bold" color="info">
                      ¿Quieres que nosotros emitamos las pruebas faltantes?
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex" justifyContent="space-evenly">
                    <MDButton
                      variant="outlined"
                      color="info"
                      onClick={() => {
                        setActiveStep(0);
                        formik.setFieldValue("product", null);
                        formik.setFieldTouched("product", false);
                        setOpenModal({ modal: null, id: null, row: null });
                      }}
                    >
                      Cancelar
                    </MDButton>
                    {openModal?.row?.extended_name_document === IvaDocumentTypes.EXTENDED_FEX ? (
                      <MDButton
                        variant="contained"
                        color="info"
                        disabled={loadingTestFex || !sellsConfig?.selectedSalePoint || !sellsConfig?.costCenter}
                        onClick={() => {
                          if (openModal?.row?.extended_name_document === IvaDocumentTypes.EXTENDED_FEX) {
                            handleSubmitFexAutoDte();
                          }
                        }}
                      >
                        {loadingTestFex ? <CircularProgress color="inherit" size={20} /> : "Aceptar"}
                      </MDButton>
                    ) : (
                      <MDButton
                        variant="contained"
                        color="info"
                        disabled={loadingAutoDtes || !sellsConfig?.selectedSalePoint || !sellsConfig?.costCenter}
                        onClick={() => {
                          setActiveStep(1);
                          getArticles({
                            variables: {
                              articlesFilters: {},
                            },
                          });
                        }}
                      >
                        {loadingAutoDtes ? <CircularProgress color="inherit" size={20} /> : "Aceptar"}
                      </MDButton>
                    )}
                  </MDBox>
                </>
              ) : (
                <MDBox color="info" display="flex" justifyContent={"center"} alignItems={"center"}>
                  <CircularProgress color="inherit" size={50} />
                </MDBox>
              )}
            </MDBox>
          ) : (
            <>
              <MDBox mb={4}>
                <MDTypography color="info" variant="h4" fontWeight="bold" mb={2}>
                  Seleciona un producto para realizar las pruebas automaticas
                </MDTypography>
                <MDTypography color="warning" variant="body" fontWeight="bold" mb={2}>
                  {openModal?.row?.extended_name_document}
                </MDTypography>
              </MDBox>
              <MDBox mb={4}>
                {loadingAutoDtes ? (
                  <MDTypography color="info" variant="body1" fontWeight="bold" mb={2}>
                    Espere mientras se hacen las pruebas automaticas. Este proceso puede durar algunos minutos.
                  </MDTypography>
                ) : (
                  <Autocomplete
                    noOptionsText="No hay productos"
                    fullWidth
                    getOptionDisabled={(option) => option.label === "No Options"}
                    onBlur={() => formik.setFieldTouched("product", true)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Productos"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {dataGetArticles.loading ? <CircularProgress color="info" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                        error={formik.values.product === null || formik.values.product?.label === "" ? Boolean(formik.touched.product) : null}
                        helperText={(formik.touched.product && formik.values.product === null) || (formik.touched.product && formik.values.product?.label === "") ? "El producto es requerido" : null}
                        variant="standard"
                      />
                    )}
                    value={formik.values.product}
                    // isOptionEqualToValue={isOptionEqualToValue}
                    onChange={async (event, newValue: any, reason) => {
                      if (reason === "clear") {
                        await formik.setFieldValue("product", null);
                        return;
                      } else {
                        formik.setFieldValue("product", newValue);
                      }
                    }}
                    options={
                      dataGetArticles?.data?.getArticles?.isSuccess && !dataGetArticles.loading
                        ? dataGetArticles?.data?.getArticles?.data
                            .filter((item: any) => {
                              return item.salesPrices.some((item: any) => item.isDefault === true);
                            })
                            .map((item: any) => {
                              return {
                                ...item,
                                label: (item.barCode ? item.barCode + " - " : "") + item.name,
                              };
                            })
                        : []
                    }
                  />
                )}
              </MDBox>
              <MDBox display="flex" justifyContent="space-evenly">
                <MDButton
                  variant="outlined"
                  color="info"
                  onClick={() => {
                    setActiveStep(0);
                    formik.setFieldValue("product", null);
                    formik.setFieldTouched("product", false);
                    setOpenModal({ modal: null, id: null, row: null });
                  }}
                >
                  Cancelar
                </MDButton>
                <MDButton
                  variant="contained"
                  color="info"
                  disabled={loadingAutoDtes || loadingTestFex}
                  onClick={() => {
                    if (formik.values.product === null) {
                      formik.setFieldTouched("product", true);
                      toast.error("Completa el formulario");
                      return;
                    } else {
                      if (openModal?.row?.extended_name_document === IvaDocumentTypes.EXTENDED_FEX) {
                        handleSubmitFexAutoDte();
                      } else {
                        formik.handleSubmit();
                      }
                      return;
                    }
                  }}
                >
                  {loadingAutoDtes || loadingTestFex ? <CircularProgress color="inherit" size={20} /> : "Aceptar"}
                </MDButton>
              </MDBox>
            </>
          )}
        </Card>
      </Modal>
    </>
  );
};

export default DTES;

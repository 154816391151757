import { ApolloLink, Observable } from "@apollo/client";
import generateJsonPdf from "services/generate-json-pdf";

enum ContingencyReason {
  OFFLINE = "Offline",
  NO_RESPONSE = "No server response",
}

export const offlineLink = new ApolloLink((operation, forward) => {
  if (operation.operationName === "AddSaleGeneralDocument") {
    if (navigator.onLine === false) {
      // generateJsonPdf({ test: "test" });

      const JsonDTEReq = localStorage.getItem("offline-package");
      const { packages } = JsonDTEReq ? JSON.parse(JsonDTEReq) : [];
      const request = {
        lastContigency: new Date().toISOString(),
        reasonContigency: ContingencyReason.OFFLINE,
        packages: [
          ...(packages || []),
          {
            date: new Date().toISOString(),
            query: operation.query,
            variables: operation.variables,
            operationName: operation.operationName,
          },
        ],
      };

      localStorage.setItem("offline-package", JSON.stringify(request));

      const offlineResponse = {
        addSaleGeneralDocument: {
          isSuccess: true,
          data: operation.variables,
          message: "Offline mutation success",
          __typename: "GenericGeneralDocumentDto",
        },
      };

      return new Observable((observer) => {
        observer.next({ data: offlineResponse });
        observer.complete();
      });
    } else {
      return forward(operation).map((response) => {
        return response;
      });
    }
  }

  return forward(operation).map((response) => {
    return response;
  });
});

// Graphql
import { useMutation } from "@apollo/client";

// Mutation
import { ADD_SALE_GENERAL_DOCUMENT } from "apollo/mutations/dtes/addSaleGeneralDocument";

// Context
import { useAddSellContext } from "context/AddSellContext";
import { useSellsConfigContext } from "context/SellsConfigContext";
import { useCompanyContext } from "context/CompanyContext";

// Libs
import { FormikState } from "formik";
import toast from "react-hot-toast";

// Types
import { SerializationKeys } from "types/apollo";
import { MINIMUM_AMOUNT } from "types/minimumAmount";
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";
import { ActiveValues } from "types/hooks/nuevoDte/activeValues";
import { SaleInformation } from "types/saleInformation";

interface Props {
  freeSale: boolean;
  setSaleInformation: React.Dispatch<React.SetStateAction<SaleInformation>>;
  handleClose: (value: ActiveValues) => void;
  handleOpen: (value: ActiveValues) => void;
}

export const useBulkSubmit = ({ freeSale = false, handleOpen, handleClose, setSaleInformation }: Props) => {
  // Context
  const {
    initialValues,
    global: { iva: calculatedIva, products, retentionISR, retention, perception, totalToPay, subTotal, paymentMethods, conditionPayment },
    resetGlobal,
  } = useAddSellContext();
  const { infoEmployeeDocuments } = useCompanyContext();
  const { sellsConfig } = useSellsConfigContext();

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  // Mutation
  const [addSaleGeneralDocument, dataAddSaleGeneralDocument] = useMutation(ADD_SALE_GENERAL_DOCUMENT, {
    context,
  });

  const handleBulkSubmit = async (values: AddSaleGeneralDocumentFilterValues, resetForm: (nextState?: Partial<FormikState<AddSaleGeneralDocumentFilterValues>>) => void) => {
    const formatedProductsAsDocumentData = products.map((product) => {
      return {
        name: product?.name,
        discount: 0,
        idArticle: product?.id < 1 ? null : product?.id,
        articleCode: product?.barCode,
        articleType: freeSale
          ? product?.type === "Otros"
            ? product?.type.slice(0, -1).toUpperCase()
            : product?.type.toUpperCase()
          : product?.productCodes?.type === "Otros"
          ? product?.productCodes?.type.slice(0, -1).toUpperCase()
          : product?.productCodes?.type.toUpperCase(),
        quantity: product?.quantity,
        salePrice: product?.unitPrice,
        idPriceList: product?.idPriceList,
        totalPrice: product?.totalPrice,
        ivaOperationType: product?.operation_type.toUpperCase(),
        description: product?.description === "" || product?.description === null ? `${product?.code ? `code ${product?.code} ${product?.name}` : `${product?.name}`}` : product?.description,
        measurementUnitCode: product?.type !== "Servicio" ? product?.measurement?.code : null,
        measurementUnitName: product?.type !== "Servicio" ? product?.measurement?.name : null,
      };
    });
    const documentData: AddSaleGeneralDocumentFilterValues = {
      detailDocuments: formatedProductsAsDocumentData,
      isSimpleBilling: freeSale ?? false,
      retention1: retention,
      perception1: perception,
      taxes: { iva: calculatedIva },
      calcuations: {
        subTotal: subTotal,
        totalAmountOperation: subTotal + calculatedIva,
        totalToPay: totalToPay,
      },
      idCashier: infoEmployeeDocuments?.idEntity,
      date: values?.date,
      idIvaDocument: values?.IvaDocumentType?.id,
      idClient: values.clientCompany?.idClientCompany,
      notifyTo: values.notifyTo || [""],
      conditionPayment: typeof values.conditionPayment === "object" ? values.conditionPayment?.value.toUpperCase() : values.conditionPayment.toUpperCase(),
      idSalePoint: sellsConfig.selectedSalePoint?.id,
      idCostCenter: sellsConfig.costCenter?.id,
      idPaymentType: 5,
      renta: parseFloat(retentionISR.toFixed(2)),
      paymentMethods: paymentMethods.map((item) => ({
        amount: item?.amount,
        paymentMethodCode: item?.paymentMethodCode,
        paymentOperationType: item?.paymentOperationType,
        idPaymentmethod: item?.selectedPaymentMethod?.id,
        numeroReferencia: item?.numeroReferencia,
      })),
      extension: {
        receiverName: values.extension?.receiverName,
        receiverDocument: values.extension?.receiverDocument,
        observations: values.extension?.observations,
      },
    };
    if (totalToPay >= MINIMUM_AMOUNT.STANDARD_MINIMUN_AMOUNT) {
      if (values.extension?.receiverName && values.extension?.receiverDocument) {
        await addSaleGeneralDocument({
          variables: {
            documentData: documentData,
          },
        })
          .then(async ({ data }) => {
            if (data.addSaleGeneralDocument.isSuccess) {
              if (data.addSaleGeneralDocument.data.status === "RECHAZADO") {
                toast.error(`Ocurrió un error al agregar venta ${data?.addSaleGeneralDocument?.data?.mhObservations}`);
                setSaleInformation({
                  errorInformation: {
                    mhObservations: data?.addSaleGeneralDocument?.data?.mhObservations,
                    nameClient: data?.addSaleGeneralDocument?.data?.clientCompany?.name,
                    generationCodeDte: data?.addSaleGeneralDocument?.data?.generationCodeDte,
                  },
                });
                handleClose(ActiveValues.paymentMethod);
                handleOpen(ActiveValues.ventaRechazada);
              } else {
                toast.success("DTE agregado correctamente");
                setSaleInformation((prev) => ({
                  ...prev,
                  successInfomation: data?.addSaleGeneralDocument?.data,
                }));
                handleClose(ActiveValues.paymentMethod);
                handleOpen(ActiveValues.ventaCompletada);
                await resetForm({
                  values: initialValues,
                });
                resetGlobal();
              }
            } else {
              toast.error(`Ocurrió un error al agregar venta  \n ${data?.addSaleGeneralDocument?.message?.detail}`);
            }
          })
          .catch((err) => {
            console.log("err", err);
            toast.error(`Ocurrió un error al agregar venta \n Por favor confirma que los campos son correctos e intente nuevamente o contacta a soporte`);
          });
      } else {
        toast.error("Por favor completa los campos requeridos");
      }
    } else {
      await addSaleGeneralDocument({
        variables: {
          documentData: documentData,
        },
      })
        .then(async ({ data }) => {
          if (data.addSaleGeneralDocument.isSuccess) {
            if (data.addSaleGeneralDocument.data.status === "RECHAZADO") {
              toast.error(`Ocurrió un error al agregar venta ${data?.addSaleGeneralDocument?.data?.mhObservations}`);
              setSaleInformation({
                errorInformation: {
                  mhObservations: data?.addSaleGeneralDocument?.data?.mhObservations,
                  nameClient: data?.addSaleGeneralDocument?.data?.clientCompany?.name,
                  generationCodeDte: data?.addSaleGeneralDocument?.data?.generationCodeDte,
                },
              });
              handleClose(ActiveValues.paymentMethod);
              handleOpen(ActiveValues.ventaRechazada);
            } else {
              toast.success("DTE agregado correctamente");
              setSaleInformation({
                successInfomation: data?.addSaleGeneralDocument?.data,
              });
              handleClose(ActiveValues.paymentMethod);
              handleOpen(ActiveValues.ventaCompletada);
              await resetForm({
                values: initialValues,
              });
              resetGlobal();
            }
          } else {
            toast.error(`Ocurrió un error al agregar venta  \n ${data?.addSaleGeneralDocument?.message?.detail}`);
          }
        })
        .catch((err) => {
          console.log("err", err);
          toast.error(`Ocurrió un error al agregar venta \n Por favor confirma que los campos son correctos e intente nuevamente o contacta a soporte`);
        });

      handleClose(ActiveValues.paymentMethod);
      resetGlobal();
    }
  };

  return { handleBulkSubmit, dataAddSaleGeneralDocument };
};

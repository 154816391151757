import gql from "graphql-tag";

export const GET_PRICE_LISTS = gql`
  query GET_PRICE_LISTS($filters: PriceListQueryFilterDti!) {
    getPriceLists(filters: $filters) {
      isSuccess
      data {
        id
        name
      }
    }
  }
`;

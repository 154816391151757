import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from "@apollo/client";
import { persistCache, LocalStorageWrapper } from "apollo3-cache-persist";
import { setContext } from "apollo-link-context";
import { RetryLink } from "apollo-link-retry";
import QueueLink from "apollo-link-queue";
import SerializingLink from "apollo-link-serialize";
import { offlineLink } from "./links/generate-dte-link";

const queueLink = new QueueLink();

const serializingLink = new SerializingLink();

const cache = new InMemoryCache();

const retryLink = new RetryLink({
  delay: {
    initial: 1000,
    min: 1000,
    max: 2000,
  },
  attempts: {
    max: 4,
    retryIf: (error, _operation) => !!error,
  },
});

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const company = JSON.parse(localStorage.getItem("company"));
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "user-company": company ? `${company.id}` : "",
    },
  };
});

await persistCache({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
}).then(() => {
  const REVALIDATION_INTERVAL = 60000 * 30;

  setInterval(() => {
    client.resetStore();
  }, REVALIDATION_INTERVAL);
});

const client = new ApolloClient({
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
    query: {
      fetchPolicy: "cache-and-network",
    },
  },
  link: ApolloLink.from([serializingLink, offlineLink, authLink.concat(httpLink)]),
  // link: ApolloLink.from([queueLink, serializingLink, retryLink, authLink.concat(httpLink)]),
});

export { client, queueLink };

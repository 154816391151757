export interface IvaDocument {
  id?: number;
  name_document?: string;
  extended_name_document?: string;
  createdAt?: string;
  updatedAt?: string;
  isActive?: boolean;
  billingEnvironment?: string;
  idDteEnvironment?: string;
  code?: string;
  description?: string;
  version?: string;
}

export enum IvaDocumentTypes {
  EXTENDED_FAC = "Facturas Consumidor Final",
  EXTENDED_CCF = "Comprobante de Crédito Fiscal",
  EXTENDED_FEX = "Factura de Exportación",
  FEX = "FEX",
  FAC = "FAC",
  CCF = "CCF",
}

import { useCallback, useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Modules
import { useDropzone, FileWithPath } from "react-dropzone";

// Types
import { Props } from "types/react";

// Libs
import { toast } from "react-hot-toast";

interface extendedProps extends Props {
  height?: number;
  handleDrop: (acceptedFiles: any) => void;
  env?: string;
}

const CustomDropZone = ({ children, handleDrop, height = 320, env }: extendedProps) => {
  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[], fileRejections: any) => {
      console.log("drop", acceptedFiles);

      fileRejections && fileRejections.length > 0 && toast.error("Solo archivos con extensión .ctr son aceptados");

      acceptedFiles && acceptedFiles.length > 0 && handleDrop(acceptedFiles);
    },
    [env]
  );

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, fileRejections } = useDropzone({
    onDrop,
    accept: {
      "application/epub+zip": [".epub", ".crt"],
    },
  });

  useEffect(() => {
    if (acceptedFiles) {
      acceptedFiles.splice(0, acceptedFiles.length);
    }
  }, [env]);
  return (
    <MDBox id="dropzone" {...getRootProps()} height={height} justifyContent="center" position="relative" border={2} borderColor={acceptedFiles && acceptedFiles.length > 0 ? "green" : "info"} borderRadius={"lg"}>
      <Grid p={5} container height="100%" justifyContent="start" alignItems="center" overflow="hidden" sx={{ cursor: "pointer", opacity: isDragActive ? 0.1 : 1 }}>
        <Grid item alignContent="start" justifyContent="flex-start">
          {!acceptedFiles ||
            (acceptedFiles.length === 0 && (
              <>
                <MDTypography color="info" variant="h5" fontWeight="bold">
                  Arrastra el certificado hasta aquí
                </MDTypography>
                <MDTypography color="secondary" variant="subtitle2">
                  Solo archivos con extensión .ctr son aceptados
                </MDTypography>
              </>
            ))}
          {acceptedFiles && acceptedFiles.length > 0 && (
            <>
              <MDTypography color="success" variant="h5" fontWeight="bold">
                Certificado cargado:
              </MDTypography>
              <MDTypography
                sx={{ wordBreak: "break-all" }}
                // wordBreak="break-all"
                color="secondary"
                variant="h6"
                fontWeight="bold"
              >
                {acceptedFiles[0].name}
              </MDTypography>
            </>
          )}
        </Grid>
      </Grid>
      {isDragActive && (
        <Grid container position="absolute" left={0} top={0} width="100%" height="100%" justifyContent="center" alignItems="center">
          <Grid>
            <MDTypography color="info" variant="h5" fontWeight="bold">
              Validar certificado
            </MDTypography>
          </Grid>
        </Grid>
      )}
      <input {...getInputProps()} />
    </MDBox>
  );
};

export default CustomDropZone;

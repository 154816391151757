import { useEffect, useMemo, useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import Footer from "views/Footer";
import DashboardLayout from "views/LayoutContainers/DashboardLayout";
import DashboardNavbar from "views/Navbars/DashboardNavbar";

// Material Dashboard 2 PRO React TS examples components
import DataTable from "views/Tables/DataTable";

// @mui material components
import Grid from "@mui/material/Grid";
import { Autocomplete, Card, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, CircularProgress } from "@mui/material";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

//react-router-dom
import { useLocation } from "react-router-dom";

// Custom Components
import MenuDropdown from "layouts/contactos/clientes/components/MenuDropdown";
import StepperCat from "components/Global/StepperCat";
import ImportLDP from "./Imports/ArticulosImportLDP";
import ExportLDP from "./Exports/ExportLDP";

//Alert
import { toast } from "react-hot-toast";

// GraphQl
import { gql, useLazyQuery, useMutation } from "@apollo/client";

//Formik
import { useFormik } from "formik";
import * as Yup from "yup";

//types
import { LabelandId } from "types/labelAndId";
import { NameAndId } from "./DetallesLDP";
import { SerializationKeys } from "types/apollo";

const columns = [
  { Header: "Nombre", accessor: "nombre" },
  { Header: "Email", accessor: "email" },
  { Header: "Telefono", accessor: "telefono" },
  { Header: "NIT", accessor: "nit" },
  { Header: "NRC/DUI", accessor: "nrc_dui" },
  { Header: "Menu", accessor: "menu", hiddeSort: true, align: "center" },
];
const options = ["Eliminar"];

const GET_CLIENTS_BY_PRICE_LIST = gql`
  query GET_ARTICLES_BY_PRICE_LIST($filters: ClientsByPriceListDtiQueryFilter!) {
    getClientsByPriceList(filters: $filters) {
      isSuccess
      data {
        id
        name
        emailUser
        emailNotifications
        phone
        idClientCompany
        tradename
        address
        idClientPriceList
        documents {
          documentNumber
          type
        }
      }
      message {
        detail
        code
        message
      }
    }
  }
`;
const DELETE_CLIENT_FROM_PRICE_LIST = gql`
  mutation DELETE_CLIENT_FROM_PRICE_LIST($idClientPriceList: Float!) {
    deleteClientFromPriceList(idClientPriceList: $idClientPriceList) {
      isSuccess
      message {
        detail
        code
        message
      }
    }
  }
`;
const ADD_CLIENTS_PRICE_LIST = gql`
  mutation ADD_CLIENTS_PRICE_LIST($data: AddClientsToPriceListDti!) {
    addClientsToPriceList(data: $data) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
const GET_CLIENTS = gql`
  query {
    getClients(clientFilters: {}) {
      isSuccess
      data {
        name
        id
        idClientCompany
        idClientPriceList
        emailNotifications
        phone
        documents {
          id
          documentNumber
          type
        }
      }
      message {
        code
        message
        detail
      }
    }
  }
`;

function ClientesLDPTable() {
  const location = useLocation();

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Inventories };

  // Queries and mutations
  const [getClientsByPriceList, dataGetClientsByPriceList] = useLazyQuery(GET_CLIENTS_BY_PRICE_LIST, { context });
  const [getClients, dataGetClients] = useLazyQuery(GET_CLIENTS, { context });
  const [deleteClientFromPriceList, dataDeleteClientFromPriceList] = useMutation(DELETE_CLIENT_FROM_PRICE_LIST, { context });
  const [addClientsToPriceList, dataAddClientsToPriceList] = useMutation(ADD_CLIENTS_PRICE_LIST, { context });

  // States
  const [activeStep, setActiveStep] = useState(0);
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [clients, setClients] = useState<LabelandId[]>([{ label: "No Options", id: 0 }]);
  const [selectedMenuOption, setSelectedMenuOption] = useState({
    option: "",
    id: "",
  });
  const [nameDelete, setNameDelete] = useState<NameAndId>();
  const [open, setOpen] = useState({
    openAdd: false,
    openDelete: false,
  });

  const validationSchema = Yup.object({
    client: Yup.object({
      id: Yup.number(),
      label: Yup.string(),
    })
      .nullable()
      .required("El cliente es requerido"),
    nameClientDelete: Yup.string().oneOf([nameDelete?.name, null], "El nombre del cliente debe coincidir"),
  });

  const formik = useFormik({
    initialValues: {
      client: {
        id: 0,
        label: "",
      },
      nameClientDelete: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      addClientsToPriceList({
        variables: {
          data: {
            idPriceList: Number(location.state.id),
            idClients: [Number(values.client.id)],
          },
        },
      })
        .then((data) => {
          const { isSuccess, message } = data.data.addClientsToPriceList;
          if (isSuccess) {
            getClientsByPriceList({
              variables: {
                filters: {
                  idPriceList: Number(location.state.id),
                  pagination: 10000,
                },
              },
            });
            formik.setFieldValue("client", {
              id: 0,
              label: "",
            });
            formik.setFieldTouched("client", false);
            toast.success("Cliente agregado correctamente");
            handleClose(true);
          } else {
            toast.error(`Hubo un error al agregar el cliente ${message.detail}`);
          }
        })
        .catch((e) => {
          toast.error(`Hubo un error al agregar el cliente ${e}`);
        });
    },
  });

  const handleDeleteClientLDP = () => {
    if (nameDelete?.id !== null || nameDelete?.name !== "" || nameDelete !== null) {
      deleteClientFromPriceList({
        variables: {
          idClientPriceList: Number(nameDelete?.id),
        },
      })
        .then((data) => {
          if (data.data.deleteArticleFromPriceList.isSuccess) {
            getClientsByPriceList({
              variables: {
                filters: {
                  idPriceList: Number(location.state.id),
                  pagination: 10000,
                },
              },
            });
            toast.success("Cliente eliminado correctamente");
            handleClose(false);
            setNameDelete({ name: "", id: null });
            formik.setFieldValue("nameClientDelete", "");
            formik.setFieldTouched("nameClientDelete", false);
          } else {
            toast.error(`Hubo un error al eliminar el cliente ${data.data.deleteArticleFromPriceList.message.detail}`);
          }
        })
        .catch((e) => {
          toast.error(`Hubo un error al eliminar el cliente ${e}`);
        });
    }
  };

  const handleClickOpen = (value: boolean) => {
    if (value) {
      setOpen((prev) => ({
        ...prev,
        openAdd: true,
      }));
    } else {
      setOpen((prev) => ({
        ...prev,
        openDelete: true,
      }));
    }
  };

  function handleClose(value: boolean) {
    if (value) {
      setOpen((prev) => ({
        ...prev,
        openAdd: false,
      }));
    } else {
      setOpen((prev) => ({
        ...prev,
        openDelete: false,
      }));
    }
  }

  //Effects
  useEffect(() => {
    if (location.state.id) {
      getClientsByPriceList({
        variables: {
          filters: {
            idPriceList: Number(location.state.id),
            pagination: 10000,
          },
        },
      });
    }
  }, [location.state.id]);

  useEffect(() => {
    if (dataGetClientsByPriceList.data) {
      setRowsData(dataGetClientsByPriceList.data?.getClientsByPriceList?.data);
    }
  }, [dataGetClientsByPriceList.data]);

  useEffect(() => {
    if (dataGetClients?.data) {
      setClients(
        dataGetClients?.data?.getClients?.data.map((item: any) => ({
          id: item.id,
          label: item.name,
        }))
      );
    }
  }, [dataGetClients?.data]);

  useEffect(() => {
    if (open.openAdd) {
      getClients(); /*.then((data) => console.log(data  .data.getArticles?.data.filter((item) => item) ));*/
    }
  }, [open.openAdd]);

  useEffect(() => {
    if (selectedMenuOption.option === "Eliminar") {
      const name = dataGetClientsByPriceList.data?.getClientsByPriceList?.data.filter((item: any) => item.idClientPriceList == selectedMenuOption.id);
      setNameDelete(() => ({
        id: name[0].idClientPriceList,
        name: name[0].name,
      }));
      handleClickOpen(false);
    }
  }, [selectedMenuOption]);

  //Data
  const rows = useMemo(() => {
    return rowsData.map((item) => ({
      nombre: item.name,
      email: item.emailNotifications ?? "No hay email",
      telefono: item.phone ?? "No hay telefono",
      nit: item.documents.find((elem: any) => elem.type === "NIT")?.documentNumber ?? "No hay NIT",
      nrc_dui: item.documents.find((elem: any) => elem.type === "DUI" || elem.type === "NRC")?.documentNumber ?? "No hay NRC/DUI",
      menu: <MenuDropdown options={options} id={String(item.idClientPriceList)} onSelectOption={setSelectedMenuOption} />,
    }));
  }, [rowsData]);

  const table = {
    columns: columns || [],
    rows: rows.length < 1 ? [{ nombre: "No hay informacion" }] : rows,
  };

  const steps = [
    {
      label: "Detalles",
      icon: <ContactMailOutlinedIcon />,
      component: (
        <>
          <MDBox>
            <MDTypography color="info" variant="h4" fontWeight="bold">
              {`Artículos asosiados a la lista de precios: "${location.state.namePriceList}"`}
            </MDTypography>
            <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
              Administración de Artículos Asociados a la Lista de Precios.
            </MDTypography>
          </MDBox>
          <DataTable
            table={dataGetClientsByPriceList.loading ? { columns: columns, rows: [] } : table}
            entriesPerPage={{ defaultValue: 10, entries: ["5", "10", "20"] }}
            canSearch
            pagination={{ variant: "contained", color: "info" }}
            loading={dataGetClientsByPriceList.loading /*  || dataDeleteClientCompany.loading */}
          >
            <Grid container alignItems="center" justifyContent="end">
              <MDButton onClick={() => handleClickOpen(true)} color="info">
                Agregar Cliente
              </MDButton>
            </Grid>
          </DataTable>
          <Dialog
            open={open.openAdd}
            keepMounted
            onClose={() => {
              handleClose(true);
              formik.setFieldValue("client", {
                id: 0,
                label: "",
              });
              formik.setFieldTouched("client", false);
            }}
          >
            <DialogTitle>{"¿Desea agregar un cliente a esta lista de precios?"}</DialogTitle>
            <MDTypography sx={{ px: 2 }} variant="subtitle2" fontWeight="regular" color="secondary">
              Busca un cliente para agregarlo a la lista de precios.
            </MDTypography>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Autocomplete
                    id="client"
                    freeSolo
                    options={clients}
                    value={formik.values.client}
                    getOptionDisabled={(option) => option.label === "No Options" && option.id === 0}
                    onChange={(e, value) => formik.setFieldValue("client", value)}
                    onBlur={() => formik.setFieldTouched("client", true)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Nombre del Cliente"
                        error={Boolean(formik.errors.client) && Boolean(formik.touched.client)}
                        helperText={!formik.touched.client || (formik.touched.client && !formik.errors.client) ? " " : `${formik.errors.client}`}
                        variant="standard"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <MDButton
                onClick={() => {
                  handleClose(true);
                  formik.setFieldValue("client", {
                    id: 0,
                    label: "",
                  });
                  formik.setFieldTouched("client", false);
                }}
                variant="outlined"
                color="info"
              >
                Atras
              </MDButton>
              <MDButton
                disabled={dataAddClientsToPriceList.loading}
                onClick={() => {
                  formik.handleSubmit();
                }}
                startIcon={dataAddClientsToPriceList.loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : null}
                variant="gradient"
                type="submit"
                color="info"
              >
                {dataAddClientsToPriceList.loading ? "Enviando" : "Enviar"}
              </MDButton>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open.openDelete}
            keepMounted
            onClose={() => {
              handleClose(false);
              formik.setFieldValue("nameClientDelete", "");
              formik.setFieldTouched("nameClientDelete", false);
            }}
          >
            <DialogTitle
              children={
                <Typography color="error" variant="h4" fontWeight="bold">
                  Desvincular Cliente{" "}
                </Typography>
              }
            />
            <DialogContent>
              <MDBox>
                <MDTypography color="info" variant="h6" fontWeight="bold">
                  {`¿Estas seguro que quieres desvincular este cliente de la lista de: ${location.state.namePriceList}?`}
                </MDTypography>
                <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
                  Confirma que quieres{" "}
                  <MDTypography variant="button" fontSize="1rem" fontWeight="bold" color="error">
                    {`Desvincular`}
                  </MDTypography>{" "}
                  de la lista de precios este cliente llamado:{" "}
                  <MDTypography variant="button" fontSize="1rem" fontWeight="bold" color="error">
                    {`"${nameDelete?.name}".`}{" "}
                  </MDTypography>
                  Escribiendo su nombre en el siguiente campo de texto:
                </MDTypography>
              </MDBox>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    error={Boolean(formik.errors.nameClientDelete) && formik.touched.nameClientDelete}
                    onBlur={() => formik.setFieldTouched("nameClientDelete", true)}
                    label="Nombre del Cliente"
                    name="nameClientDelete"
                    helperText={!formik.touched.nameClientDelete || (formik.touched.nameClientDelete && !formik.errors.nameClientDelete) ? "Escribe el nombre exacto del articulo a eliminar" : formik.errors.nameClientDelete}
                    onChange={formik.handleChange}
                    variant="standard"
                    value={formik.values.nameClientDelete}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <MDButton
                onClick={() => {
                  handleClose(false);
                  formik.setFieldValue("nameClientDelete", "");
                  formik.setFieldTouched("nameClientDelete", false);
                }}
                variant="outlined"
                color="info"
              >
                cancelar
              </MDButton>
              <MDButton
                disabled={dataDeleteClientFromPriceList.loading || Boolean(formik.errors.nameClientDelete) || formik.values.nameClientDelete === ""}
                onClick={() => {
                  handleDeleteClientLDP();
                }}
                startIcon={dataDeleteClientFromPriceList.loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : null}
                variant="contained"
                type="submit"
                color="error"
              >
                {dataDeleteClientFromPriceList.loading ? "Eliminando..." : "Eliminar"}
              </MDButton>
            </DialogActions>
          </Dialog>
        </>
      ),
    },
    {
      label: "Importar",
      icon: <FileUploadOutlinedIcon />,
      component: (
        <ImportLDP>
          <>
            <MDTypography color="info" variant="h4" fontWeight="bold">
              {`Importar clientes a la Lista de Precios llamada ${location.state.namePriceList}`}
            </MDTypography>

            <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
              {`En esta parte podrás importar clientes a la listas de precio llamada ${location.state.namePriceList}, pero debes de cumplir con el formato que puedes descargar en el siguiente botón:`}
            </MDTypography>
          </>
        </ImportLDP>
      ),
    },
    {
      label: "Exportar",
      icon: <FileDownloadOutlinedIcon />,
      component: (
        <ExportLDP name="Clientes">
          <>
            <MDTypography color="info" variant="h4" fontWeight="bold">
              {`Exportar clientes de la Listas de Precios llamada ${location.state.namePriceList}`}
            </MDTypography>
            <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
              {`Solamente haz clic en el botón de descargar y podrás obtener toda la información de los clientes relacionados a la listas de precios llamada ${location.state.namePriceList} en un archivo de excel.`}
            </MDTypography>
          </>
        </ExportLDP>
      ),
    },
  ];

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={5} mb={9} minHeight="100vh">
          <MDBox>
            <StepperCat color="info" steps={steps} state={[activeStep, setActiveStep]} />
          </MDBox>
          <Card sx={{ p: 4 }}>{steps[activeStep].component}</Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}
export default ClientesLDPTable;

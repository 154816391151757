import { useEffect, useMemo, useState } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import DataTable from "views/Tables/DataTable";

// @mui material components
import Grid from "@mui/material/Grid";
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, CircularProgress } from "@mui/material";

//react-router-dom
import { useNavigate } from "react-router-dom";

// Custom Components
import MenuDropdown from "../../../contactos/clientes/components/MenuDropdown";

//Alert
import { toast } from "react-hot-toast";

// GraphQl
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";

//Formik
import { useFormik } from "formik";
import * as Yup from "yup";

// Types
import { SerializationKeys } from "types/apollo";
import { PAGINATION } from "constants/pagination";

const columns = [
  { Header: "Nombre", accessor: "nombre", width: "90%" },
  { Header: "Menu", accessor: "menu", hiddeSort: true, align: "center", width: "10%" },
];
const options = ["Editar", "Articulos Asociados", "Clientes Asociados", "Ptos de Vtas Asociados", "Agregar Art. por bodega completa", "Archivar"];

const GET_PRICE_LISTS = gql`
  query {
    getPriceLists(filters: {}) {
      isSuccess
      data {
        id
        name
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
const DELETE_PRICE_LISTS = gql`
  mutation DELETE_PRICE_LISTS($idPriceList: Float!) {
    deletePriceList(idPriceList: $idPriceList) {
      isSuccess
      message {
        code
        message
        detail
      }
    }
  }
`;
const ADD_PRICE_LISTS = gql`
  mutation ADD_PRICE_LISTS($data: PriceListDti!) {
    addPriceList(data: $data) {
      isSuccess
      data {
        id
        name
        description
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
const GET_COST_CENTERS = gql`
  query GET_COST_CENTERS($filters: CostCenterQueryFilterDti!) {
    getCostsCenters(filters: $filters) {
      isSuccess
      data {
        id
        name
        storages {
          id
          name
        }
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
const GET_STORAGES = gql`
  query GET_STORAGES($filter: StoragesQueryFilterDti) {
    getStorages(filter: $filter) {
      isSuccess
      data {
        name
        id
      }
      message {
        code
        message
        detail
      }
    }
  }
`;
const ADD_ARTICLE_FROM_STORAGE_TO_PRICE_LIST = gql`
  mutation ADD_ARTICLE_FROM_STORAGE_TO_PRICE_LIST($idStorage: Float!, $idPriceList: Float!) {
    addArticlesFromStorageToPriceList(idStorage: $idStorage, idPriceList: $idPriceList) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
const UPDATE_PRICE_LIST = gql`
  mutation UPDATE_PRICE_LIST($idPriceList: Float!, $data: PriceListDti!) {
    updatePriceList(idPriceList: $idPriceList, data: $data) {
      isSuccess
      message {
        code
        detail
        message
      }
    }
  }
`;
interface Rows {
  id: number;
  name: string;
}
export interface NameAndId {
  id: number;
  name: string;
}

function DetallesLDP() {
  const navigate = useNavigate();

  // GraphQL Context
  const context = { serializationKey: SerializationKeys.Inventories };

  // Queries and mutations
  const { data, loading, error, refetch } = useQuery(GET_PRICE_LISTS, { context, variables: { pagination: PAGINATION } });
  const [getCostsCenters, dataGetCostsCenters] = useLazyQuery(GET_COST_CENTERS, { context });
  const [getStorages, dataGetStorages] = useLazyQuery(GET_STORAGES, { context });
  const [addArticlesFromStorageToPriceList, dataAddArticlesFromStorageToPriceList] = useMutation(ADD_ARTICLE_FROM_STORAGE_TO_PRICE_LIST, { context });
  const [deletePriceList, dataDeletePriceList] = useMutation(DELETE_PRICE_LISTS, { context });
  const [addPriceList, dataAddPriceList] = useMutation(ADD_PRICE_LISTS, { context });
  const [updatePriceList, dataUpdatePriceList] = useMutation(UPDATE_PRICE_LIST, { context });

  //Constantes
  const valuesHandleClick = ["add", "delete", "addStorage", "editPriceList"];

  const [rowsData, setRowsData] = useState<Rows[]>([]);
  const [costCenters, setCostCenters] = useState([{ label: "No Options", id: 0 }]);
  const [storages, setStorages] = useState([{ label: "No Options", id: 0 }]);
  const [selectedMenuOption, setSelectedMenuOption] = useState({
    option: "",
    id: "",
  });
  const [nameDelete, setNameDelete] = useState<NameAndId>();
  const [open, setOpen] = useState({
    openAdd: false,
    openDelete: false,
    openAddStorage: false,
    openEditPriceList: false,
  });

  const validationSchema = Yup.object({
    name: Yup.string().required("El nombre de la lista de precio debe tener al menos 1 caracter"),
    description: Yup.string().required("La descripcion de la lista de precio debe tener al menos 1 caracter"),
    nameDeleteForm: Yup.string().oneOf([nameDelete?.name, null], "El nombre de la lista debe coincidir"),
    costCenter: Yup.object({
      id: Yup.number(),
      label: Yup.string(),
    }).nullable(),
    storage: Yup.object({
      id: Yup.number(),
      label: Yup.string(),
    }).nullable(),
    updateName: Yup.string(),
    updateDescription: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      nameDeleteForm: "",
      costCenter: {
        id: 0,
        label: "",
      },
      storage: {
        id: 0,
        label: "",
      },
      updateName: "",
      updateDescription: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      addPriceList({
        variables: {
          data: {
            name: values.name,
            description: values.description,
          },
        },
      })
        .then((data) => {
          const { isSuccess, message } = data.data.addPriceList;
          if (isSuccess) {
            refetch();
            formik.setFieldValue("name", "");
            formik.setFieldTouched("name", false);
            formik.setFieldValue("description", "");
            formik.setFieldTouched("description", false);
            toast.success("Lista de precio creada correctamente");
            handleClose(valuesHandleClick[0]);
          } else {
            formik.setFieldValue("name", "");
            formik.setFieldTouched("name", false);
            formik.setFieldValue("description", "");
            formik.setFieldTouched("description", false);
            toast.error(`Hubo un error al crear la lista de precio ${message.detail}`);
            handleClose(valuesHandleClick[0]);
          }
        })
        .catch((e) => {
          formik.setFieldValue("name", "");
          formik.setFieldTouched("name", false);
          formik.setFieldValue("description", "");
          formik.setFieldTouched("description", false);
          toast.error(`Hubo un error al crear la lista de precio ${e}`);
          handleClose(valuesHandleClick[0]);
        });
    },
  });

  const handleClickOpen = (value: string) => {
    if (value === "add") {
      setOpen((prev) => ({
        ...prev,
        openAdd: true,
      }));
    }
    if (value === "delete") {
      setOpen((prev) => ({
        ...prev,
        openDelete: true,
      }));
    }
    if (value === "addStorage") {
      setOpen((prev) => ({
        ...prev,
        openAddStorage: true,
      }));
    }
    if (value === "editPriceList") {
      setOpen((prev) => ({
        ...prev,
        openEditPriceList: true,
      }));
    }
  };

  function handleClose(value: string) {
    if (value === "add") {
      setOpen((prev) => ({
        ...prev,
        openAdd: false,
      }));
    }
    if (value === "delete") {
      setOpen((prev) => ({
        ...prev,
        openDelete: false,
      }));
    }
    if (value === "addStorage") {
      setOpen((prev) => ({
        ...prev,
        openAddStorage: false,
      }));
    }
    if (value === "editPriceList") {
      setOpen((prev) => ({
        ...prev,
        openEditPriceList: false,
      }));
    }
  }

  const handleDeleteLDP = () => {
    if (nameDelete?.id !== null && nameDelete?.name !== "" && nameDelete !== null) {
      deletePriceList({
        variables: {
          idPriceList: Number(nameDelete.id),
        },
      })
        .then((data) => {
          if (data.data.deletePriceList.isSuccess) {
            refetch();
            toast.success("Lista de precio eliminada correctamente");
            handleClose(valuesHandleClick[1]);
            setNameDelete({ name: "", id: null });
            formik.setFieldValue("nameDeleteForm", "");
            formik.setFieldTouched("nameDeleteForm", false);
          } else {
            toast.error(`Hubo un error al eliminar la lista de precio ${data.data.deletePriceList.message.detail}`);
            handleClose(valuesHandleClick[1]);
            setNameDelete({ name: "", id: null });
            formik.setFieldValue("nameDeleteForm", "");
            formik.setFieldTouched("nameDeleteForm", false);
          }
        })
        .catch((e) => {
          toast.error(`Hubo un error al eliminar la lista de precio ${e}`);
          handleClose(valuesHandleClick[1]);
          setNameDelete({ name: "", id: null });
          formik.setFieldValue("nameDeleteForm", "");
          formik.setFieldTouched("nameDeleteForm", false);
        });
    }
  };

  const handleSubmitArticleFromStorageToPriceList = () => {
    if (formik.values.storage.id !== 0 && formik.values.storage !== null) {
      addArticlesFromStorageToPriceList({
        variables: {
          idStorage: Number(formik.values.storage.id),
          idPriceList: Number(selectedMenuOption.id),
        },
      })
        .then((data) => {
          if (data.data.addArticlesFromStorageToPriceList.isSuccess) {
            toast.success("Articulos agregados correctamente");
            handleClose(valuesHandleClick[2]);
            formik.setFieldValue("costCenter", {
              id: 0,
              label: "",
            });
            formik.setFieldTouched("costCenter", false);
            formik.setFieldValue("storage", {
              id: 0,
              label: "",
            });
            formik.setFieldTouched("storage", false);
          } else {
            toast.error(`Hubo un error al agregar el articulo ${data.data.addArticlesFromStorageToPriceList.message.detail}`);
            handleClose(valuesHandleClick[2]);
            formik.setFieldValue("costCenter", {
              id: 0,
              label: "",
            });
            formik.setFieldTouched("costCenter", false);
            formik.setFieldValue("storage", {
              id: 0,
              label: "",
            });
            formik.setFieldTouched("storage", false);
          }
        })
        .catch((e) => {
          toast.error(`Hubo un error al agregar el articulo ${e}`);
          handleClose(valuesHandleClick[2]);
          formik.setFieldValue("costCenter", {
            id: 0,
            label: "",
          });
          formik.setFieldTouched("costCenter", false);
          formik.setFieldValue("storage", {
            id: 0,
            label: "",
          });
          formik.setFieldTouched("storage", false);
        });
    }
  };

  const handleSubmitEditPriceList = () => {
    updatePriceList({
      variables: {
        idPriceList: Number(selectedMenuOption.id),
        data: {
          name: formik.values.updateName,
          description: formik.values.updateDescription,
        },
      },
    })
      .then((data) => {
        const { isSuccess, message } = data.data.updatePriceList;
        if (isSuccess) {
          refetch();
          formik.setFieldValue("updateName", "");
          formik.setFieldTouched("updateName", false);
          formik.setFieldValue("updateDescription", "");
          formik.setFieldTouched("updateDescription", false);
          toast.success("Lista de precio actualizada correctamente");
          handleClose(valuesHandleClick[3]);
        } else {
          formik.setFieldValue("updateName", "");
          formik.setFieldTouched("updateName", false);
          formik.setFieldValue("updateDescription", "");
          formik.setFieldTouched("updateDescription", false);
          toast.error(`Hubo un error al actualizar la lista de precio ${message.detail}`);
          handleClose(valuesHandleClick[3]);
        }
      })
      .catch((e) => {
        formik.setFieldValue("updateName", "");
        formik.setFieldTouched("updateName", false);
        formik.setFieldValue("updateDescription", "");
        formik.setFieldTouched("updateDescription", false);
        toast.error(`Hubo un error al actualizar la lista de precio ${e}`);
        handleClose(valuesHandleClick[3]);
      });
  };

  //Effects
  useEffect(() => {
    if (data) {
      setRowsData(data?.getPriceLists.data);
    }
  }, [data]);

  useEffect(() => {
    if (open.openAddStorage) {
      getCostsCenters({
        variables: {
          filters: {
            pagination: PAGINATION,
          },
        },
      });
    }
  }, [open.openAddStorage]);

  useEffect(() => {
    if (formik.values.costCenter.id) {
      getStorages({
        variables: {
          filter: {
            idCostCenter: Number(formik.values.costCenter.id),
            pagination: PAGINATION,
          },
        },
      });
    }
  }, [formik.values.costCenter.id]);

  useEffect(() => {
    if (dataGetCostsCenters?.data) {
      setCostCenters(
        dataGetCostsCenters?.data?.getCostsCenters?.data.map((item: any) => ({
          id: item.id,
          label: item.name,
        }))
      );
    }
  }, [dataGetCostsCenters?.data]);

  useEffect(() => {
    if (dataGetStorages?.data) {
      setStorages(
        dataGetStorages?.data?.getStorages?.data.map((item: any) => ({
          id: item.id,
          label: item.name,
        }))
      );
    }
  }, [dataGetStorages?.data]);

  useEffect(() => {
    console.log(selectedMenuOption);
    if (selectedMenuOption.option === "Editar") {
      handleClickOpen(valuesHandleClick[3]);
    }
    if (selectedMenuOption.option === "Clientes Asociados") {
      const namePriceList = data?.getPriceLists.data.filter((item: any) => item.id == selectedMenuOption.id)[0]?.name;
      navigate(`/inventarios/lista-de-precio/clientesAsociados`, { state: { id: selectedMenuOption.id, namePriceList } });
    }
    if (selectedMenuOption.option === "Articulos Asociados") {
      const namePriceList = data?.getPriceLists.data.filter((item: any) => item.id == selectedMenuOption.id)[0]?.name;
      navigate(`/inventarios/lista-de-precio/artiulosAsociados`, { state: { id: selectedMenuOption.id, namePriceList } });
    }
    if (selectedMenuOption.option === "Ptos de Vtas Asociados") {
      const namePriceList = data?.getPriceLists.data.filter((item: any) => item.id == selectedMenuOption.id)[0]?.name;
      navigate(`/inventarios/lista-de-precio/ptosDeVtasAsociados`, { state: { id: selectedMenuOption.id, namePriceList } });
    }
    if (selectedMenuOption.option === "Agregar Art. por bodega completa") {
      handleClickOpen(valuesHandleClick[2]);
    }
    if (selectedMenuOption.option === "Archivar") {
      const name = data?.getPriceLists.data.filter((item: any) => item.id == selectedMenuOption.id);
      setNameDelete(() => ({
        id: name[0].id,
        name: name[0].name,
      }));
      handleClickOpen(valuesHandleClick[1]);
    }
  }, [selectedMenuOption]);

  //Data
  const rows = useMemo(() => {
    return rowsData.map((item) => ({
      nombre: item.name,
      menu: <MenuDropdown options={options} id={String(item.id)} onSelectOption={setSelectedMenuOption} />,
    }));
  }, [rowsData]);

  const table = {
    columns: columns || [],
    rows: rows.length < 1 ? [{ nombre: "No hay informacion" }] : rows,
  };
  return (
    <>
      <MDBox>
        <MDTypography color="info" variant="h4" fontWeight="bold">
          Administracion de las Listas de Precios
        </MDTypography>
        <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
          Aqui podras buscar todas las listas de precios.
        </MDTypography>
      </MDBox>
      <DataTable
        table={/* dataDeleteClientCompany.loading ? { columns: columns, rows: [] } :  */ table}
        entriesPerPage={{ defaultValue: 10, entries: ["5", "10", "20"] }}
        canSearch
        pagination={{ variant: "contained", color: "info" }}
        loading={loading /*  || dataDeleteClientCompany.loading */}
      >
        <Grid container alignItems="center" justifyContent="end">
          <MDButton onClick={() => handleClickOpen(valuesHandleClick[0])} color="info">
            Crear Nuevo Lista
          </MDButton>
        </Grid>
      </DataTable>
      <Dialog
        open={open.openAdd}
        keepMounted
        onClose={() => {
          handleClose(valuesHandleClick[0]);
          formik.setFieldValue("name", "");
          formik.setFieldTouched("name", false);
          formik.setFieldValue("description", "");
          formik.setFieldTouched("description", false);
        }}
      >
        <DialogTitle>{"¿Desea agregar una nueva lista de precios?"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={Boolean(formik.errors.name) && formik.touched.name}
                onBlur={() => formik.setFieldTouched("name", true)}
                label="Nombre de la Lista de Precios"
                name="name"
                helperText={!formik.touched.name || (formik.touched.name && !formik.errors.name) ? " " : formik.errors.name}
                onChange={formik.handleChange}
                variant="standard"
                value={formik.values.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descripcion"
                multiline
                maxRows={4}
                name="description"
                error={Boolean(formik.errors.description) && formik.touched.description}
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={() => formik.setFieldTouched("description", true)}
                helperText={!formik.touched.description || (formik.touched.description && !formik.errors.description) ? " " : formik.errors.description}
                variant="standard"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <MDButton
            onClick={() => {
              handleClose(valuesHandleClick[0]);
              formik.setFieldValue("name", "");
              formik.setFieldTouched("name", false);
              formik.setFieldValue("description", "");
              formik.setFieldTouched("description", false);
            }}
            variant="outlined"
            color="info"
          >
            Atras
          </MDButton>
          <MDButton
            disabled={dataAddPriceList.loading || formik.values.name === "" || formik.values.description === ""}
            onClick={() => {
              formik.handleSubmit();
            }}
            startIcon={dataAddPriceList.loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : null}
            variant="gradient"
            type="submit"
            color="info"
          >
            {dataAddPriceList.loading ? "Enviando" : "Enviar"}
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open.openDelete}
        keepMounted
        onClose={() => {
          handleClose(valuesHandleClick[1]);
          formik.setFieldValue("nameDeleteForm", "");
          formik.setFieldTouched("nameDeleteForm", false);
        }}
      >
        <DialogTitle
          children={
            <Typography color="error" variant="h4" fontWeight="bold">
              Eliminar Lista de Precios
            </Typography>
          }
        />
        <DialogContent>
          <MDBox>
            <MDTypography color="info" variant="h6" fontWeight="bold">
              {`¿Estas seguro que quieres eliminar esta lista llamada: ${nameDelete?.name}?`}
            </MDTypography>
            <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
              Confirma que quieres{" "}
              <MDTypography variant="button" fontSize="1rem" fontWeight="bold" color="error">
                {`Eliminar`}
              </MDTypography>{" "}
              la lista de precios llamada:{" "}
              <MDTypography variant="button" fontSize="1rem" fontWeight="bold" color="error">
                {`"${nameDelete?.name}".`}{" "}
              </MDTypography>
              Escribiendo su nombre en el siguiente campo de texto:
            </MDTypography>
          </MDBox>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={Boolean(formik.errors.nameDeleteForm) && formik.touched.nameDeleteForm}
                onBlur={() => formik.setFieldTouched("nameDeleteForm", true)}
                label="Nombre"
                name="nameDeleteForm"
                helperText={!formik.touched.nameDeleteForm || (formik.touched.nameDeleteForm && !formik.errors.nameDeleteForm) ? "Escribe el nombre exacto de la lista a eliminar" : formik.errors.nameDeleteForm}
                onChange={formik.handleChange}
                variant="standard"
                value={formik.values.nameDeleteForm}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <MDButton
            onClick={() => {
              handleClose(valuesHandleClick[1]);
              formik.setFieldValue("nameDeleteForm", "");
              formik.setFieldTouched("nameDeleteForm", false);
            }}
            variant="outlined"
            color="info"
          >
            cancelar
          </MDButton>
          <MDButton
            disabled={dataDeletePriceList.loading || Boolean(formik.errors.nameDeleteForm) || formik.values.nameDeleteForm === ""}
            onClick={() => {
              handleDeleteLDP();
            }}
            startIcon={dataDeletePriceList.loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : null}
            variant="contained"
            type="submit"
            color="error"
          >
            {dataDeletePriceList.loading ? "Eliminando..." : "Eliminar"}
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open.openAddStorage}
        keepMounted
        onClose={() => {
          handleClose(valuesHandleClick[2]);
          formik.setFieldValue("costCenter", {
            id: 0,
            label: "",
          });
          formik.setFieldTouched("costCenter", false);
          formik.setFieldValue("storage", {
            id: 0,
            label: "",
          });
          formik.setFieldTouched("storage", false);
        }}
      >
        <DialogTitle>{"Asigna un artículos a la Lista de Precios"}</DialogTitle>
        <DialogContent>
          <MDBox>
            <MDTypography variant="subtitle2" fontWeight="regular" color="secondary">
              Todos los artículos de esta bodega se agregaran a la lista de precios
            </MDTypography>
          </MDBox>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Autocomplete
                id="costCenter"
                freeSolo
                options={costCenters}
                getOptionDisabled={(option) => option.label === "No Options" && option.id === 0}
                value={formik.values.costCenter}
                onChange={(e, value) => formik.setFieldValue("costCenter", value)}
                onBlur={() => formik.setFieldTouched("costCenter", true)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Centro de Costos"
                    error={Boolean(formik.errors.costCenter) && Boolean(formik.touched.costCenter)}
                    helperText={!formik.touched.costCenter || (formik.touched.costCenter && !formik.errors.costCenter) ? " " : `${formik.errors.costCenter}`}
                    variant="standard"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="storage"
                freeSolo
                options={storages}
                getOptionDisabled={(option) => option.label === "No Options" && option.id === 0}
                value={formik.values.storage}
                onChange={(e, value) => formik.setFieldValue("storage", value)}
                onBlur={() => formik.setFieldTouched("storage", true)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bodegas"
                    error={Boolean(formik.errors.storage) && Boolean(formik.touched.storage)}
                    helperText={!formik.touched.storage || (formik.touched.storage && !formik.errors.storage) ? " " : `${formik.errors.storage}`}
                    variant="standard"
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <MDButton
            onClick={() => {
              handleClose(valuesHandleClick[2]);
              formik.setFieldValue("costCenter", {
                id: 0,
                label: "",
              });
              formik.setFieldTouched("costCenter", false);
              formik.setFieldValue("storage", {
                id: 0,
                label: "",
              });
              formik.setFieldTouched("storage", false);
            }}
            variant="outlined"
            color="info"
          >
            Atras
          </MDButton>
          <MDButton
            disabled={dataAddArticlesFromStorageToPriceList.loading}
            onClick={() => {
              handleSubmitArticleFromStorageToPriceList();
            }}
            startIcon={dataAddArticlesFromStorageToPriceList.loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : null}
            variant="gradient"
            type="submit"
            color="info"
          >
            {dataAddArticlesFromStorageToPriceList.loading ? "Enviando" : "Enviar"}
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open.openEditPriceList}
        keepMounted
        onClose={() => {
          handleClose(valuesHandleClick[3]);
          formik.setFieldValue("updateName", "");
          formik.setFieldTouched("updateName", false);
          formik.setFieldValue("updateDescription", "");
          formik.setFieldTouched("updateDescription", false);
        }}
      >
        <DialogTitle>{"¿Desea editar una lista de precios?"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={Boolean(formik.errors.updateName) && formik.touched.updateName}
                onBlur={() => formik.setFieldTouched("updateName", true)}
                label="Nombre de la Lista de Precios"
                name="updateName"
                helperText={!formik.touched.updateName || (formik.touched.updateName && !formik.errors.updateName) ? " " : formik.errors.updateName}
                onChange={formik.handleChange}
                variant="standard"
                value={formik.values.updateName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descripcion"
                multiline
                maxRows={4}
                name="updateDescription"
                error={Boolean(formik.errors.updateDescription) && formik.touched.updateDescription}
                value={formik.values.updateDescription}
                onChange={formik.handleChange}
                onBlur={() => formik.setFieldTouched("updateDescription", true)}
                helperText={!formik.touched.updateDescription || (formik.touched.updateDescription && !formik.errors.updateDescription) ? " " : formik.errors.updateDescription}
                variant="standard"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <MDButton
            onClick={() => {
              handleClose(valuesHandleClick[3]);
              formik.setFieldValue("updateName", "");
              formik.setFieldTouched("updateName", false);
              formik.setFieldValue("updateDescription", "");
              formik.setFieldTouched("updateDescription", false);
            }}
            variant="outlined"
            color="info"
          >
            Atras
          </MDButton>
          <MDButton
            disabled={dataUpdatePriceList.loading || formik.values.updateName === "" || formik.values.updateDescription === ""}
            onClick={() => {
              handleSubmitEditPriceList();
            }}
            startIcon={dataUpdatePriceList.loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : null}
            variant="gradient"
            type="submit"
            color="info"
          >
            {dataUpdatePriceList.loading ? "Enviando" : "Enviar"}
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default DetallesLDP;

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import TextField from "@mui/material/TextField";

// @mui material components
import Grid from "@mui/material/Grid";
import { Autocomplete, Card } from "@mui/material";

// Context
import { useFormikContext } from "formik";

// Custom Components
import CustomFieldError from "components/Global/Forms/CustomFieldError";

// Types
import { DocumentType } from "types/documentType";
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";

const documentTypes = ["NRC", "DUI", "NIT", "Pasaporte"];

const Receiver = () => {
  // Context
  const { values, setFieldValue, setValues, touched, setFieldTouched, errors } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  const handleChangeFormat = (e: any) => {
    if (values.documentType === DocumentType.NIT) {
      e.currentTarget.maxLength = 14;
      let string = e.currentTarget.value;
      string = string.replace(/\D/g, "");
      string = string.replace(/(\d{4})(\d{6})(\d{3})(\d{1})/, "$1-$2-$3-$4");
      setFieldValue("extension.receiverDocument", string);
    }
    if (values.documentType === DocumentType.DUI) {
      e.currentTarget.maxLength = 9;
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{8})(\d{1})/, "$1-$2");
      setFieldValue("extension.receiverDocument", value);
    }
    if (values.documentType === DocumentType.NRC) {
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d+)(\d{1})/, "$1-$2");
      setFieldValue("extension.receiverDocument", value);
    }
    if (values.documentType === DocumentType.PASSPORT) {
      setFieldValue("extension.receiverDocument", e.currentTarget.value);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Card sx={{ padding: 5 }}>
          <MDBox display="flex" alignItems="center">
            <MDTypography color="info" variant="h4">
              Quién Recibe
            </MDTypography>
          </MDBox>
          <Grid container gap={2} my={5} mb={1} flexWrap="nowrap" flexDirection={{ xs: "column", md: "row" }}>
            <Grid item width={{ sx: "100%", md: "50%" }}>
              <MDInput
                fullWidth
                onBlur={() => setFieldTouched("extension.receiverName", true)}
                sx={{ input: { paddingY: "1.04rem" } }}
                label="Nombre de quien recibe"
                value={values.extension.receiverName}
                onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeFormat(e);
                }}
              />
              <CustomFieldError touched={touched.extension ? true : false} errorName={errors.extension && (errors.extension?.receiverName as string)} />
            </Grid>
            <Grid item width={{ xs: "100%", lg: "50%" }}>
              <Autocomplete
                openOnFocus
                fullWidth
                value={values.documentType}
                renderInput={(params) => (
                  <MDBox position="relative">
                    <TextField {...params} label="Tipo de Documento" />
                    <MDBox position="absolute" width="100%">
                      <CustomFieldError touched={touched?.documentType ? true : false} errorName={errors?.documentType as string} />
                    </MDBox>
                  </MDBox>
                )}
                onChange={async (_, newValue, reason) => {
                  if (reason === "clear") {
                    setValues((prev) => ({
                      ...prev,
                      documentType: null,
                      extension: {
                        ...prev.extension,
                        receiverDocument: "",
                      },
                    }));
                    return;
                  } else {
                    setValues((prev) => ({
                      ...prev,
                      documentType: newValue,
                      extension: {
                        ...prev.extension,
                        receiverDocument: "",
                      },
                    }));
                    return;
                  }
                }}
                isOptionEqualToValue={(option: string, value: string) => option === value}
                options={documentTypes}
              />
            </Grid>
            <Grid item width={{ sx: "100%", md: "50%" }}>
              <TextField
                fullWidth
                sx={{ input: { paddingY: "1.04rem" } }}
                onBlur={() => setFieldTouched("extension.receiverDocument", true)}
                disabled={!Boolean(values.documentType)}
                label="Documento de identificación"
                value={values.extension.receiverDocument}
                onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeFormat(e);
                }}
              />
              <CustomFieldError touched={touched.extension ? true : false} errorName={errors.extension && (errors.extension?.receiverDocument as string)} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};

export default Receiver;

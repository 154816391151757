import { useContext, useEffect, useRef, useState } from "react";

// @mui material components
import { Autocomplete, Card, Grid, TextField } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Queries
import { GET_MEASUREMENTS } from "apollo/queries/measurements/getMeasurements";

// Graphql
import { useQuery } from "@apollo/client";

// Alert
import toast from "react-hot-toast";

// Custom components
import CustomFieldError from "components/Global/Forms/CustomFieldError";
import ShortCutsLabels from "layouts/ventas/nuevoDTE/components/filters/ShortCutsLabels";

// Context
import { ShortcutContext } from "context/ShortcutContext";
import { KeyboardContext } from "context/KeyboardContext";
import { useFormikContext } from "formik";

// Type
import { SerializationKeys } from "types/apollo";
import { LabelandId } from "types/labelAndId";
import { OperationType } from "types/environment";
import { IvaDocumentTypes } from "types/iva-document";
import { AddSaleGeneralDocumentFilterValues } from "types/mutations/addSaleGeneralDocument";
import { PAGINATION } from "constants/pagination";

interface MeasurementUnit extends LabelandId {
  code?: string;
  symbol?: string;
}

interface DetailFilterSimple {
  refDetailFilterSimple?: React.MutableRefObject<HTMLInputElement>;
}

function AddDetailsDteFilterSimple({ refDetailFilterSimple }: DetailFilterSimple) {
  // GraphQL Context
  const context = { serializationKey: SerializationKeys.SellsDTES };

  // Context
  const { triggerListener, registerListener } = useContext(ShortcutContext);
  const { keys } = useContext(KeyboardContext);
  const { values, setFieldValue, setValues, touched, setFieldTouched, handleSubmit, errors } = useFormikContext<AddSaleGeneralDocumentFilterValues>();

  //States
  const [measurementUnit, setMeasurementUnit] = useState<MeasurementUnit[]>([{ label: "No Options", id: 0 }]);

  // Queries
  const dataMeasurementUnit = useQuery(GET_MEASUREMENTS, { context, variables: { measurementsFilters: { pagination: PAGINATION } } });

  const isOptionEqualToValue = (option: any, value: any) => option === value;

  // Refs
  const ProductCode = useRef<HTMLInputElement>(null);
  const quantity = useRef<HTMLInputElement>(null);
  const salePrice = useRef<HTMLInputElement>(null);
  const operation_type = useRef<HTMLInputElement>(null);
  const Type = useRef<HTMLInputElement>(null);
  const articleCode = useRef<HTMLInputElement>(null);
  const MeasurementUnit = useRef<HTMLInputElement>(null);

  //Effects
  useEffect(() => {
    if (dataMeasurementUnit?.data) {
      setMeasurementUnit(
        dataMeasurementUnit.data.getMeasurements?.data.map((item: any) => ({
          id: item.id,
          label: item?.name,
          code: item?.code,
          symbol: item?.symbol,
        }))
      );
    }
  }, [dataMeasurementUnit.data]);
  useEffect(() => {
    registerListener(new Set(["control", "t"]), () => {
      if (operation_type.current) {
        operation_type.current.focus();
        operation_type.current.click();
      }
    });
    registerListener(new Set(["control", "j"]), () => {
      if (MeasurementUnit.current) {
        MeasurementUnit.current.focus();
        MeasurementUnit.current.click();
      }
    });
    registerListener(new Set(["control", "d"]), () => {
      if (Type.current) {
        Type.current.focus();
        Type.current.click();
      }
    });
    registerListener(new Set(["control", "h"]), () => {
      const childNode = articleCode?.current?.querySelector<HTMLInputElement>("#articleCode");
      if (childNode) {
        childNode.focus();
        childNode.click();
      }
    });
    registerListener(new Set(["control", "i"]), () => {
      const childNode = ProductCode?.current?.querySelector<HTMLInputElement>("#ProductCode");
      if (childNode) {
        childNode.focus();
        childNode.click();
      }
    });
    registerListener(new Set(["control", "g"]), () => {
      const childNode = salePrice?.current?.querySelector<HTMLInputElement>("#salePrice");
      if (childNode) {
        childNode.focus();
        childNode.click();
      }
    });
    registerListener(new Set(["control", "q"]), () => {
      const childNode = quantity?.current?.querySelector<HTMLInputElement>("#quantity");
      if (childNode) {
        childNode.focus();
        childNode.click();
      }
    });

    return () => {
      // removeListener(keys);
    };
  }, [triggerListener]);

  return (
    <>
      <Grid item xs={12} ref={refDetailFilterSimple ? refDetailFilterSimple : null}>
        <Card sx={{ padding: 5 }}>
          <MDBox>
            <MDTypography color="info" variant="h4">
              Agregar Detalles del Documento Electronico Tributario
            </MDTypography>
          </MDBox>
          <Grid container gap={{ xs: 3, lg: 5 }} my={5} mb={1}>
            <Grid container wrap="nowrap" gap={{ xs: 3, lg: 5 }} flexDirection={{ xs: "column", lg: "row" }}>
              <Grid item width={{ xs: "100%", lg: "50%" }}>
                <MDBox position="relative">
                  <TextField
                    fullWidth
                    id="ProductCode"
                    label="Nombre del Producto*"
                    ref={ProductCode}
                    sx={{ input: { paddingY: "1.04rem" } }}
                    value={values.productCodes?.name ?? ""}
                    onBlur={() => setFieldTouched("productCodes", true)}
                    onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                      await setFieldValue("productCodes", { ...values.productCodes, name: event.target.value });
                    }}
                  />
                  <ShortCutsLabels
                    keys={keys}
                    command={[
                      { key: "control", label: "CTRL" },
                      { key: "i", label: "I" },
                    ]}
                  />
                  <MDBox position="absolute" width="100%">
                    <CustomFieldError name="test" touched={touched.productCodes ? true : false} errorName={errors.productCodes && "El campo 'Nombre del Producto' es requerido"} />
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid item width={{ xs: "100%", lg: "50%" }}>
                <MDBox position="relative">
                  <TextField
                    type="number"
                    id="quantity"
                    label="Cantidad*"
                    ref={quantity}
                    fullWidth
                    disabled={values.productCodes?.name ? false : true}
                    inputProps={{ step: 0.01, min: 0 }}
                    value={values.quantity}
                    sx={{ input: { paddingY: "1.04rem" } }}
                    onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                      const updatedDetailDocument = {
                        ...values.detailDocument,
                        quantity: parseFloat(event.target.value),
                      };
                      setValues((prev) => ({
                        ...prev,
                        quantity: parseFloat(event.target.value),
                        detailDocument: {
                          ...prev.detailDocument,
                          quantity: parseFloat(event.target.value),
                        },
                      }));
                    }}
                  />
                  <ShortCutsLabels
                    keys={keys}
                    command={[
                      { key: "control", label: "CTRL" },
                      { key: "q", label: "Q" },
                    ]}
                  />
                  <MDBox position="absolute" width="100%">
                    <CustomFieldError name="test" touched={touched.quantity ? true : false} errorName={errors.quantity && (errors.quantity as string)} />
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>

            <Grid container wrap="nowrap" gap={{ xs: 3, lg: 5 }} flexDirection={{ xs: "column", lg: "row" }}>
              <Grid item width={{ xs: "100%", lg: "50%" }}>
                <Autocomplete
                  ref={Type}
                  openOnFocus
                  options={["Bienes", "Servicios", "Ambos"]}
                  isOptionEqualToValue={(option, value) => option === value}
                  value={values.type}
                  onChange={async (e, value) => {
                    await setFieldValue("type", value);
                  }}
                  onBlur={() => setFieldTouched("type", true)}
                  renderInput={(params) => (
                    <MDBox position="relative">
                      <TextField {...params} label="Tipo*" id="type" />
                      <ShortCutsLabels
                        keys={keys}
                        command={[
                          { key: "control", label: "CTRL" },
                          { key: "d", label: "D" },
                        ]}
                      />
                      <MDBox position="absolute" width="100%">
                        <CustomFieldError name="test" touched={touched.type ? true : false} errorName={errors.type && (errors.type as string)} />
                      </MDBox>
                    </MDBox>
                  )}
                />
              </Grid>
              <Grid item width={{ xs: "100%", lg: "50%" }}>
                <MDBox position="relative">
                  <TextField
                    fullWidth
                    label="Código de producto"
                    id="articleCode"
                    ref={articleCode}
                    sx={{ input: { paddingY: "1.04rem" } }}
                    value={values.productCodes?.code ?? ""}
                    // onBlur={() => setFieldTouched("detailDocument.description", true)}
                    onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                      await setFieldValue("productCodes", { ...values.productCodes, code: event.target.value });
                    }}
                  />
                  <ShortCutsLabels
                    keys={keys}
                    command={[
                      { key: "control", label: "CTRL" },
                      { key: "h", label: "H" },
                    ]}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" gap={{ xs: 3, lg: 5 }} flexDirection={{ xs: "column", lg: "row" }} justifyContent="center" alignItems="center">
              <Grid item width={{ xs: "100%", lg: "20%" }}>
                <MDBox position="relative">
                  <TextField
                    id="salePrice"
                    label="Precio Unitario*"
                    fullWidth
                    ref={salePrice}
                    disabled={values?.productCodes?.name ? false : true}
                    sx={{ input: { paddingY: "1.04rem" } }}
                    value={values.salePrice?.label ?? ""}
                    onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                      if (!isNaN(Number(event.target.value))) {
                        setValues((prev) => ({
                          ...prev,
                          salePrice: {
                            label: event.target.value,
                          },
                          detailDocument: {
                            ...values.detailDocument,
                            salePrice: parseFloat(event.target.value),
                          },
                        }));
                      } else if (event.target.value === "") {
                        setValues((prev) => ({
                          ...prev,
                          salePrice: null,
                          detailDocument: {
                            ...values.detailDocument,
                            salePrice: null,
                          },
                        }));
                      }
                    }}
                  />
                  <ShortCutsLabels
                    keys={keys}
                    command={[
                      { key: "control", label: "CTRL" },
                      { key: "g", label: "G" },
                    ]}
                  />
                  <MDBox position="absolute" width="100%">
                    <CustomFieldError touched={touched?.salePrice ? true : false} errorName={errors?.salePrice as string} />
                  </MDBox>
                </MDBox>
              </Grid>
              {values?.IvaDocumentType?.name_document === IvaDocumentTypes.FEX ? (
                <Grid item width={{ xs: "100%", lg: "20%" }}>
                  <TextField
                    id="productDiscount"
                    fullWidth
                    label="Descuento del Producto"
                    sx={{ input: { paddingY: "1.04rem" } }}
                    value={values.productDiscount}
                    onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                      if (!isNaN(Number(event.target.value))) {
                        await setFieldValue("productDiscount", event.target.value);
                      }
                    }}
                  />
                </Grid>
              ) : (
                <Grid item width={{ xs: "100%", lg: "20%" }}>
                  <Autocomplete
                    id="operation_type"
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    openOnFocus
                    disabled={values?.productCodes?.name ? false : true}
                    options={[
                      { label: OperationType.GRAVADAS, value: Object.keys(OperationType).find((key) => (OperationType as any)[key] === OperationType.GRAVADAS) },
                      { label: OperationType.EXENTAS, value: Object.keys(OperationType).find((key) => (OperationType as any)[key] === OperationType.EXENTAS) },
                      { label: OperationType.NO_SUJETAS, value: Object.keys(OperationType).find((key) => (OperationType as any)[key] === OperationType.NO_SUJETAS) },
                    ]}
                    value={values.operation_type}
                    onChange={async (event, newValue, reason) => {
                      if (values.clientCompany?.isExent && typeof newValue === "object" && newValue.label === OperationType.GRAVADAS) {
                        toast.error(`El cliente ${values.clientCompany?.name} es exento de IVA, por favor modifica el contacto o selecciona una opción válida`, {
                          duration: 5000,
                        });
                        setFieldValue("operation_type", null);
                      } else {
                        if (reason === "clear") {
                          await setFieldValue("operation_type", null);
                        } else {
                          await setFieldValue("operation_type", newValue);
                        }
                      }
                    }}
                    onBlur={() => setFieldTouched("operation_type", true)}
                    renderInput={(params) => (
                      <MDBox position="relative">
                        <TextField {...params} label="Gravado*" ref={operation_type} />
                        <ShortCutsLabels
                          keys={keys}
                          command={[
                            { key: "control", label: "CTRL" },
                            { key: "t", label: "T" },
                          ]}
                        />
                        <MDBox position="absolute" width="100%">
                          <CustomFieldError name="test" touched={touched.operation_type ? true : false} errorName={errors.operation_type && (errors.operation_type as string)} />
                        </MDBox>
                      </MDBox>
                    )}
                  />
                </Grid>
              )}
              {values?.type === "Ambos" || values?.type === "Bienes" || values?.type === "Otro" ? (
                <Grid item width={{ xs: "100%", lg: "20%" }}>
                  <Autocomplete
                    id="measurementUnit"
                    freeSolo
                    ref={MeasurementUnit}
                    openOnFocus
                    options={measurementUnit ?? [{ label: "No hay unidades de medida", id: 0 }]}
                    value={values.productCodes?.measurement?.name}
                    disabled={values?.productCodes?.name ? false : true}
                    getOptionDisabled={(option) => option.label === "No Options" && option.id === 0}
                    onChange={async (e, value) => {
                      await setFieldValue("productCodes", {
                        ...values.productCodes,
                        measurement: {
                          code: typeof value === "object" ? value?.code : null,
                          name: typeof value === "object" ? value?.label : null,
                          id: typeof value === "object" ? value?.id : null,
                          symbol: typeof value === "object" ? value?.symbol : null,
                        },
                      });
                    }}
                    onBlur={() => setFieldTouched("measurementUnit", true)}
                    renderInput={(params) => (
                      <MDBox position="relative">
                        <TextField {...params} label="Unidad de Medida*" />
                        <ShortCutsLabels
                          keys={keys}
                          command={[
                            { key: "control", label: "CTRL" },
                            { key: "j", label: "J" },
                          ]}
                        />
                        <MDBox position="absolute" width="100%">
                          <CustomFieldError name="test" touched={touched.measurementUnit ? true : false} errorName={errors.measurementUnit && (errors.measurementUnit as string)} />
                        </MDBox>
                      </MDBox>
                    )}
                  />
                </Grid>
              ) : null}
              <Grid item width={{ xs: "100%", lg: "20%" }}>
                <TextField
                  id="totalPrice"
                  fullWidth
                  disabled
                  label="Total"
                  sx={{ input: { paddingY: "1.04rem" } }}
                  value={(values.detailDocument?.salePrice && values.detailDocument?.quantity && ((values.detailDocument?.salePrice - Number(values.productDiscount ?? "")) * values.detailDocument?.quantity).toFixed(2)) || ""}
                  onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                    const updatedDetailDocument = {
                      ...values.detailDocument,
                      totalPrice: parseFloat(event.target.value),
                    };
                    await setFieldValue("detailDocument", updatedDetailDocument);
                  }}
                />
              </Grid>

              <Grid item width={{ xs: "100%", lg: "10%" }}>
                <MDButton
                  id="addDetailDocument"
                  fullWidth
                  onClick={async () => {
                    handleSubmit();
                  }}
                  color="info"
                  size="small"
                >
                  Agregar
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
}

export default AddDetailsDteFilterSimple;
